<script>
  let groupUrls = [
    { count: 2, url: "https://example.com/1" },
    { count: 3, url: "https://example.com/2" },
    { count: 4, url: "https://example.com/3" },
  ];

  $: {
    let ifAllGroupsAreFilled = groupUrls.find(
      (groupUrl) => groupUrl.url.trim() === ""
    );
    groupUrls = [
      ...groupUrls,
      ...(!ifAllGroupsAreFilled ? [{ count: 0, url: "" }] : []),
    ];
  }
  let loading = false;
  let isDisabled = false;

  // disable if all urls are empty or all counts are 0
  $: isDisabled =
    groupUrls.every(
      (groupUrl) => groupUrl.url.trim() === "" || !groupUrl.count
    ) || loading;

  let data = null;

  function createUrl() {
    if (loading) return;
    loading = true;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    const finalGroupUrls = groupUrls.filter(
      (groupUrl) => groupUrl.url.trim() !== "" && groupUrl.count
    );

    var raw = JSON.stringify({
      groupUrls: finalGroupUrls.map(({ url }) => url),
      groupTimes: finalGroupUrls.map(({ count }) => count),
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      "https://us-central1-url-shortener-8ec5a.cloudfunctions.net/api/url",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => (data = result))
      .catch((error) => console.log("error", error))
      .then(() => (loading = false));
  }
</script>

<div
  class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0"
>
  <div class="rounded-t bg-white mb-0 px-6 py-6">
    <div class="text-center flex justify-between">
      <h6 class="text-blueGray-700 text-xl font-bold">My account</h6>
      <button
        class="bg-black text-white active:bg-black font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150 {isDisabled
          ? 'opacity-50 cursor-not-allowed'
          : ''}"
        type="button"
        disabled={isDisabled}
        on:click={createUrl}
      >
        Create url
      </button>
    </div>
  </div>
  <div class="flex-auto px-4 lg:px-10 py-10 pt-0">
    <form>
      <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
        Url Information
      </h6>
      <div class="flex flex-wrap">
        {#each groupUrls as groupUrl}
          <div class="w-full lg:w-8/12 px-4">
            <div class="relative w-full mb-3">
              <label
                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                for="grid-username"
              >
                Url Address
              </label>
              <input
                id="grid-username"
                type="text"
                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                bind:value={groupUrl.url}
              />
            </div>
          </div>
          <div class="w-full lg:w-4/12 px-4">
            <div class="relative w-full mb-3">
              <label
                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                for="grid-email"
              >
                Url Count
              </label>
              <input
                id="grid-email"
                type="number"
                pattern="[0-9]*"
                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                bind:value={groupUrl.count}
              />
            </div>
          </div>
        {/each}
      </div>

      <hr class="mt-6 border-b-1 border-blueGray-300" />

      {#if data?.id}
        <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
          Url Information
        </h6>
        https://us-central1-url-shortener-8ec5a.cloudfunctions.net/api/url/{data.id}
      {/if}
    </form>
  </div>
</div>
