<script>
  import apiInstance from "helpers/api.js";
  import { onMount } from "svelte";

  export let color = "light";
  export let scheduledMessages = [];

  let getAllScheduledMessagesLoading = false;
  async function getAllScheduledMessages() {
    if (getAllScheduledMessagesLoading) return;
    getAllScheduledMessagesLoading = true;
    try {
      const { data } = await apiInstance.listScheduledMessages();
      scheduledMessages = data;
    } catch (error) {
      console.error(error);
    }
    getAllScheduledMessagesLoading = false;
  }

  onMount(() => {
    getWhatsAppProfiles();
    getAllScheduledMessages();
  });

  let getListWhatsAppProfiles = false;
  let whatsappProfiles = [];

  async function getWhatsAppProfiles() {
    if (getListWhatsAppProfiles) return;
    getListWhatsAppProfiles = true;
    try {
      const { data } = await apiInstance.listWhatsAppProfiles({
        filter: {},
      });
      whatsappProfiles = data.map((item) => ({
        label: `${item.clientInfo.me.user} - ${item.clientInfo.pushName}`,
        value: item.id,
        data: item,
      }));
      console.log(whatsappProfiles);
    } catch (error) {
      console.error(error);
    }
    getListWhatsAppProfiles = false;
  }

  let deleting = {};
  async function onDelete(id) {
    if (deleting[id]) {
      return;
    }
    deleting[id] = true;
    try {
      await apiInstance.deleteScheduledMessage({ id });
      getAllScheduledMessages();
    } catch (error) {
      console.error(error);
    }
    deleting[id] = false;
  }
  $: console.log("whatsappProfiles", whatsappProfiles);
</script>

<table class="items-center w-full bg-transparent border-collapse">
  <thead>
    <tr>
      <td
        class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left {color ===
        'light'
          ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
          : 'bg-black-700 text-green-200 border-red-600'}"
      >
        WhatsApp Profile
      </td>
      <th
        class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left {color ===
        'light'
          ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
          : 'bg-black-700 text-green-200 border-red-600'}"
      >
        Contacts
      </th>

      <th
        class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left {color ===
        'light'
          ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
          : 'bg-black-700 text-green-200 border-red-600'}"
      >
        Delay
      </th>

      <th
        class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left {color ===
        'light'
          ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
          : 'bg-black-700 text-green-200 border-red-600'}"
      >
        Message type
      </th>
      <th
        class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left {color ===
        'light'
          ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
          : 'bg-black-700 text-green-200 border-red-600'}"
      >
        Message text
      </th>
      <th
        class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left {color ===
        'light'
          ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
          : 'bg-black-700 text-green-200 border-red-600'}"
      >
        Extra message options
      </th>
      <th
        class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left {color ===
        'light'
          ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
          : 'bg-black-700 text-green-200 border-red-600'}"
      >
        Delete
      </th>
    </tr>
  </thead>
  <tbody>
    {#if scheduledMessages.length === 0}
      <tr>
        <td
          class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
          colspan="4"
        >
          No scheduled messages found
        </td>
      </tr>
    {/if}
    {#each scheduledMessages as scheduledMessage}
      <tr>
        <td
          class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
        >
          {whatsappProfiles.find(
            (profile) => profile.value === scheduledMessage.data.profileId
          )?.label || ""}
        </td>
        <td
          class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
        >
          {scheduledMessage.data.contacts.join(", ")}
        </td>
        <td
          class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
        >
          {scheduledMessage.data.delay / 1000} seconds
        </td>

        <td
          class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
        >
          {scheduledMessage.data.messageType}
        </td>

        <td
          class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
        >
          {scheduledMessage.data.message ||
            scheduledMessage.data?.pollOptionsExtra?.pollName ||
            scheduledMessage.data?.fileOptions?.caption ||
            ""}
        </td>
        <td
          class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
        >
          {#if scheduledMessage.data.messageType === "IMAGE" || scheduledMessage.data.messageType === "VIDEO" || scheduledMessage.data.messageType === "DOCUMENT"}
            <a
              href={scheduledMessage.data?.fileOptions?.fileUrl}
              target="_blank"
              rel="noreferrer"
              class="text-blue-500"
            >
              View Media
            </a>
          {/if}
          {#if scheduledMessage.data.messageType === "POLL"}
            {#each scheduledMessage.data?.pollOptionsExtra?.pollOptions as pollOption}
              <div>{pollOption?.name}</div>
            {/each}
          {/if}
        </td>
        <td
          class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
        >
          <button
            class="bg-black text-white active:bg-black-600 text-xs font-bold uppercase px-3 py-1 rounded outline-none focus:outline-none mr-1 mb-1 {deleting[
              scheduledMessage.id
            ] && 'cursor-not-allowed bg-black-600 text-green-200'}"
            type="button"
            style="transition: all 0.15s ease"
            on:click={() => {
              if (
                !confirm(
                  "Are you sure you want to delete this scheduled message?"
                )
              ) {
                return;
              }

              onDelete(scheduledMessage.id);
            }}
          >
            {deleting[scheduledMessage.id] ? "Deleting..." : "Delete"}
          </button>
        </td>
      </tr>
    {/each}
  </tbody>
</table>
