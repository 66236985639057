<script>
  import { signInWithCustomToken } from "firebase/auth";
  import { baseURL } from "helpers/axiosInstance.js";
  import { auth } from "utils/firebase.js";

  let signInUsername = "";
  let signInPassword = "";
  let signInError = "";
  let loading = false;

  const signIn = function () {
    signInError = "";
    loading = true;

    var req = new XMLHttpRequest();
    req.onload = function () {
      if (req.status === 400 || req.status === 401) {
        signInError = "Invalid username or password";
        return;
      }
      if (req.status !== 200) {
        signInError =
          "Invalid response from Firebase Cloud Function " + req.status;
        return;
      }
      var data = JSON.parse(req.responseText);
      if (data.token) {
        signInWithCustomToken(auth, data.token).then(() => {
          loading = false;
          window.location = "/";
        });
      } else {
        console.log("ERROR RESPONSE: " + req.responseText);
        signInError =
          "Invalid response from Firebase Cloud Function see developer console for details";
      }
    };
    req.onerror = function () {
      loading = false;
      signInError =
        "Network error in Firebase Cloud Function call see developer console for details";
    };
    var url = `${baseURL}/api/auth/login`;
    console.log("POST " + url);
    req.open("POST", url, true);
    req.setRequestHeader("Content-Type", "application/json");
    req.send(
      JSON.stringify({
        username: signInUsername,
        password: signInPassword,
      })
    );
  };
</script>

<div class="container mx-auto px-4 h-full">
  <div class="flex content-center items-center justify-center h-full">
    <div class="w-full lg:w-4/12 px-4">
      <div
        class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200 border-0"
      >
        <div class="rounded-t mb-0 px-6 py-6">
          <div class="text-center mb-3">
            <h6 class="text-blueGray-500 text-sm font-bold">Sign in with</h6>
          </div>
        </div>
        <div class="flex-auto px-4 lg:px-10 py-10 pt-0">
          <form>
            <div class="relative w-full mb-3">
              <label
                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                for="grid-email"
              >
                User name
              </label>
              <input
                id="grid-email"
                type="email"
                bind:value={signInUsername}
                autocomplete="username"
                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                placeholder="User name"
              />
            </div>

            <div class="relative w-full mb-3">
              <label
                class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                for="grid-password"
              >
                Password
              </label>
              <input
                id="grid-password"
                type="password"
                autocomplete="current-password"
                bind:value={signInPassword}
                class="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                placeholder="Password"
              />
            </div>
            {#if signInError}
              <div class="relative w-full mb-3">
                <span class="ml-2 text-sm font-semibold text-blueGray-600">
                  Error: {signInError}
                </span>
              </div>
            {/if}

            <div class="text-center mt-6">
              <button
                class="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150 {loading
                  ? 'opacity-50 cursor-not-allowed'
                  : ''}"
                type="button"
                on:click={signIn}
              >
                Sign In
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
