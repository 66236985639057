<script>
  import CardStats from "components/Cards/CardStats.svelte";
  import ProfileSelect from "components/Select/ProfileSelect.svelte";
  import "flatpickr/dist/flatpickr.css";

  import apiInstance from "helpers/api.js";
  import Tabs from "components/Tabs/Tabs.svelte";
  import MessageCountTab from "components/Tabs/MessageCountTab.svelte";
  import PollCountTab from "components/Tabs/PollCountTab.svelte";

  let selectedWhatsAppProfile;

  $: items = [
    {
      label: "Message Count",
      value: 1,
      component: MessageCountTab,
      props: { selectedWhatsAppProfile },
    },
    {
      label: "Poll Count",
      value: 2,
      component: PollCountTab,
      props: { selectedWhatsAppProfile },
    },
  ];

  let fromId;

  $: fromId = selectedWhatsAppProfile?.data?.clientInfo?.wid?._serialized;

  let groupAndContactsAnalyticsData;
  async function getWhatsAppGroupAndContactsAnalytics(params) {
    const { data } =
      await apiInstance.getWhatsAppGroupAndContactsAnalytics(params);
    groupAndContactsAnalyticsData = data;
  }

  $: {
    if (fromId) {
      getWhatsAppGroupAndContactsAnalytics({
        fromId,
        user_uid: selectedWhatsAppProfile?.data?.uid,
      });
    }
  }
</script>

<div class="flex flex-wrap">
  <div class="w-full px-4">
    <div
      class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0"
    >
      <div class="rounded-t bg-white mb-0 px-6 py-6">
        <div class="text-center flex justify-between">
          <h6 class="text-blueGray-700 text-xl font-bold">
            WhatsApp Analytics
          </h6>
          <div class="relative lg:w-6/12 mb-3">
            <ProfileSelect bind:selectedWhatsAppProfile />
          </div>
        </div>
      </div>
      {#if selectedWhatsAppProfile && groupAndContactsAnalyticsData}
        <div class="flex-auto px-4 lg:px-10 py-10 pt-0">
          <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
            Overview
          </h6>
          <div class="flex flex-wrap">
            <div class="w-full lg:w-6/12 xl:w-3/12">
              <CardStats
                statSubtitle="All Groups"
                statTitle={groupAndContactsAnalyticsData.groupCount}
                statPercentColor="text-emerald-500"
                statIconName="fas fa-chart-pie"
                statIconColor="bg-orange-500"
              />
            </div>
            <div class="w-full lg:w-6/12 xl:w-3/12 px-4">
              <CardStats
                statSubtitle="All Audiences"
                statTitle={groupAndContactsAnalyticsData.contactCount}
                statPercentColor="text-emerald-500"
                statIconName="fas fa-chart-pie"
                statIconColor="bg-orange-500"
              />
            </div>
          </div>
          <Tabs {items} />
        </div>
      {/if}
    </div>
  </div>
</div>
