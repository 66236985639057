<script>
  export let toggleModal;
  export let modalTitle = "";
</script>

<div
  class="overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center flex"
>
  <div class="relative w-auto my-6 mx-auto">
    <!--content-->
    <div
      class="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none max-h-[100vh] overflow-y-auto"
    >
      <!--header-->
      <div
        class="flex items-start justify-between p-5 border-b border-solid border-blueGray-200 rounded-t"
      >
        <h3 class="text-3xl font-semibold">{modalTitle}</h3>
        <button
          class="p-1 ml-auto bg-transparent border-0 text-black float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
          on:click={toggleModal}
        >
          <span
            class="bg-transparent text-black h-6 w-6 text-2xl block outline-none focus:outline-none"
          >
            ×
          </span>
        </button>
      </div>
      <!--body-->
      <div class="relative p-2 flex-auto">
        <p class="my-4 text-blueGray-500 text-lg leading-relaxed">
          <slot />
        </p>
      </div>
    </div>
  </div>
</div>
<div class="opacity-25 fixed inset-0 z-40 bg-black" />
