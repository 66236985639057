import axios from "axios";
import { userStore } from "store/svelteStore.js";
import { get } from "svelte/store";
import { isLocal } from "utils/envUtils.js";
import { failure } from "utils/toastUtils.js";

const authHeaders = () => {
  const user = get(userStore);
  if (user?.accessToken) {
    return {
      Authentication: `Bearer ${user.accessToken}`,
    };
  }
  return {};
};

export const baseURL = isLocal()
  ? "http://127.0.0.1:8080"
  : "https://api-fingyan.vlocal.site";

const instance = axios.create({
  baseURL,
});

// listen for 401
instance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error?.response?.status === 401) {
      failure("Session expired. Please reload");
    }
    return Promise.reject(error);
  }
);

function Axios() {
  return {
    get: function (url, headers, opts) {
      return instance.get(url, {
        headers: {
          ...authHeaders(),
          ...headers,
        },
        ...(opts || {}),
      });
    },
    post: function (url, data, headers, opts) {
      return instance.post(url, data, {
        headers: {
          ...authHeaders(),
          ...headers,
        },
        ...(opts || {}),
      });
    },
    patch: function (url, data, headers, opts) {
      return instance.patch(url, data, {
        headers: {
          ...authHeaders(),
          ...headers,
        },
        ...(opts || {}),
      });
    },
    delete: function (url, headers, opts) {
      return instance.delete(url, {
        headers: {
          ...authHeaders(),
          ...headers,
        },
        ...(opts || {}),
      });
    },
  };
}

const axiosInstance = Axios();
export default axiosInstance;
