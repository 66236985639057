<script>
  import MultiSelect from "svelte-multiselect";
  import apiInstance from "helpers/api.js";

  export let fromId;
  export let selectedWhatsAppContacts = []; // { label: "test", value: "test" }
  let whatsAppContacts = [];
  let loading = false;
  let getWhatsAppContactsLoading = false;

  function formatWhatsAppContacts(data) {
    return data.map((item) => ({
      label: `${
        item.name || item.pushname || item.verifiedName || item.shortName
      }`,
      value: item.serializedId,
    }));
  }

  async function loadWhatsAppContacts(filterText) {
    const { data } = await apiInstance.listWhatsAppContacts({
      filter: {
        name: filterText,
      },
      fromId,
    });
    whatsAppContacts = formatWhatsAppContacts(data);
    return whatsAppContacts;
  }

  let searchText = "";
  let timeout;
  $: if (searchText) {
    loading = true;
    clearTimeout(timeout);
    timeout = setTimeout(async () => {
      loadWhatsAppContacts(searchText);
      loading = false;
    }, 1000);
  }

  async function getWhatsAppContacts(filter = {}) {
    if (getWhatsAppContactsLoading) return;
    getWhatsAppContactsLoading = true;
    try {
      const { data } = await apiInstance.listWhatsAppContacts(filter);
      whatsAppContacts = formatWhatsAppContacts(data);
    } catch (error) {
      console.error(error);
    }
    getWhatsAppContactsLoading = false;
  }

  $: {
    if (fromId) {
      whatsAppContacts = [];
      getWhatsAppContacts({
        fromId,
      });
    }
  }
</script>

<MultiSelect
  bind:options={whatsAppContacts}
  bind:value={selectedWhatsAppContacts}
  bind:searchText
  resetFilterOnAdd={false}
  duplicates={true}
  outerDivClass="!bg-white"
  inputClass="!h-10"
  closeDropdownOnSelect={false}
  {loading}
/>
