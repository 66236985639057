<script>
  import apiInstance from "helpers/api.js";
  import { onMount } from "svelte";

  export let color = "light";
  export let scheduledMessages = [];

  let getAllDashboardMessagesLoading = false;
  async function getAllDashboardMessages() {
    if (getAllDashboardMessagesLoading) return;
    getAllDashboardMessagesLoading = true;
    try {
      const { data } = await apiInstance.listDashboardMessages({
        filter: {
          limit: 20,
          offset: 0,
        },
      });
      scheduledMessages = data.rows;
    } catch (error) {
      console.error(error);
    }
    getAllDashboardMessagesLoading = false;
  }

  onMount(() => {
    getAllDashboardMessages();
  });
</script>

<table class="items-center w-full bg-transparent border-collapse">
  <thead>
    <tr>
      <td
        class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left {color ===
        'light'
          ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
          : 'bg-black-700 text-green-200 border-red-600'}"
      >
        From
      </td>
      <th
        class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left {color ===
        'light'
          ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
          : 'bg-black-700 text-green-200 border-red-600'}"
      >
        to
      </th>

      <th
        class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left {color ===
        'light'
          ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
          : 'bg-black-700 text-green-200 border-red-600'}"
      >
        status
      </th>

      <th
        class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left {color ===
        'light'
          ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
          : 'bg-black-700 text-green-200 border-red-600'}"
      >
        Message type
      </th>
      <th
        class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left {color ===
        'light'
          ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
          : 'bg-black-700 text-green-200 border-red-600'}"
      >
        Message text
      </th>
      <th
        class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left {color ===
        'light'
          ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
          : 'bg-black-700 text-green-200 border-red-600'}"
      >
        Extra message options
      </th>
      <th
        class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left {color ===
        'light'
          ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
          : 'bg-black-700 text-green-200 border-red-600'}"
      >
        Created at
      </th>
    </tr>
  </thead>
  <tbody>
    {#if scheduledMessages.length === 0}
      <tr>
        <td
          class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
          colspan="4"
        >
          No messages sent
        </td>
      </tr>
    {/if}
    {#each scheduledMessages as scheduledMessage}
      <tr>
        <td
          class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
        >
          {scheduledMessage.from}
        </td>
        <td
          class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
        >
          {scheduledMessage.to}
        </td>
        <td
          class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
        >
          {scheduledMessage.status}
        </td>

        <td
          class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
        >
          {scheduledMessage.type}
        </td>
        <td
          class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
        >
          {scheduledMessage.body ||
            scheduledMessage?.pollOption?.pollName ||
            scheduledMessage?.fileCaption ||
            ""}
        </td>
        <td
          class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
        >
          {#if scheduledMessage.type === "IMAGE" || scheduledMessage.type === "VIDEO" || scheduledMessage.type === "DOCUMENT"}
            <a
              href={scheduledMessage?.fileUrl}
              target="_blank"
              rel="noreferrer"
              class="text-blue-500"
            >
              View Media
            </a>
          {/if}
          {#if scheduledMessage.type === "POLL"}
            {#each scheduledMessage?.pollOption?.pollOptions as pollOption}
              <div class="max-w-[300px] overflow-auto word-break-break-all">
                {pollOption?.name}
              </div>
            {/each}
          {/if}
          {#if scheduledMessage.type === "VOICE_NOTE"}
            <audio controls class="h-[30px]">
              <source src={scheduledMessage?.fileUrl} />
            </audio>
          {/if}
        </td>
        <td
          class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
        >
          {new Date(scheduledMessage.createdAt).toLocaleString("en-US", {
            weekday: "short",
            year: "numeric",
            month: "short",
            day: "numeric",
            hour: "numeric",
            minute: "numeric",
          })}
        </td>
      </tr>
    {/each}
  </tbody>
</table>
