<script>
  import CardBarChart from "components/Cards/CardBarChart.svelte";
  import GroupOfGroupSelect from "components/Select/GroupOfGroupSelect.svelte";
  import GroupSelect from "components/Select/GroupSelect.svelte";
  import TypesSelect from "components/Select/TypesSelect.svelte";
  import ContactSelect from "components/Select/ContactSelect.svelte";
  import Flatpickr from "svelte-flatpickr";
  import "flatpickr/dist/flatpickr.css";

  import apiInstance from "helpers/api.js";

  export let props;
  $: selectedWhatsAppProfile = props.selectedWhatsAppProfile;
  let selectedGroups = [];
  let selectedWhatsAppContacts = [];
  let selectedTypes = [
    {
      label: "Chat",
      value: "chat",
    },
  ];
  let groupOfGroups;
  let selectedRange = [
    new Date(new Date().setHours(0, 0, 0, 0) - 7 * 24 * 60 * 60 * 1000),
    new Date(new Date().setHours(23, 59, 59, 999)),
  ];

  let fromId;
  $: fromId = selectedWhatsAppProfile?.data?.clientInfo?.wid?._serialized;

  let analyticsLoading = false;
  let analyticsData = [];
  async function getWhatsAppMessageAnalytics(params = {}) {
    if (analyticsLoading) return;
    analyticsLoading = true;
    try {
      const { data } = await apiInstance.getWhatsAppMessageAnalytics(params);
      analyticsData = data;
      analyticsLoading = false;
      return analyticsData;
    } catch (error) {
      console.error(error);
      analyticsLoading = false;
    }
  }

  let readData = []
  let readRate = 0
  let readLoading = false
  async function getReadRate(params = {}) {
    if(!params.contacts || params.contacts.length <= 0) {
      readData = []
      readRate = 0
      return
    }

    if (analyticsLoading) return;
    readLoading = true;
    try {
      const { data } = await apiInstance.listReadRate(params);
      const d = params.contacts.map(x => data[x]).flat()

      for(let i = 0; i < d.length; i++) {
        readRate += d[i].readRate
      }

      readRate = (readRate / d.length) % 100

      readData = d;
      readLoading = false;
      return readData;
    } catch (error) {
      console.error(error);
      readLoading = false;
    }
  }

  $: {
    if(filter) {
      getReadRate({
        contacts: filter.chatIds
      })
    }
  }

  $: filter = {
    chatIds: [
      ...new Set(
        selectedGroups
          .map((group) => group.value)
          .concat(
            groupOfGroups?.data?.groups?.map((group) => group.serializedId) ||
              []
          )
          .concat(selectedWhatsAppContacts.map((contact) => contact.value))
      ),
    ],
    startTime: selectedRange[0],
    endTime: selectedRange[1],
    type: selectedTypes?.map((type) => type.value),
    timeInterval: "day",
  };

  $: {
    if (selectedWhatsAppProfile && filter) {
      getWhatsAppMessageAnalytics({
        fromId: selectedWhatsAppProfile?.data?.uid,
        filter,
      });
    }
  }
  const options = {
    mode: "range",
    dateFormat: "Y-m-d",
  };

  function handleChange(event) {
    const [selectedDates] = event.detail;
    if (selectedDates.length !== 2) return;
    selectedRange = [
      new Date(selectedDates[0].setHours(0, 0, 0, 0)),
      new Date(selectedDates[1].setHours(23, 59, 59, 999)),
    ];
  }

  let groupAndContactsAnalyticsData;
  async function getWhatsAppGroupAndContactsAnalytics(params) {
    const { data } =
      await apiInstance.getWhatsAppGroupAndContactsAnalytics(params);
    groupAndContactsAnalyticsData = data;
  }

  $: {
    if (fromId) {
      getWhatsAppGroupAndContactsAnalytics({
        fromId,
        user_uid: selectedWhatsAppProfile?.data?.uid,
      });
    }
  }
</script>

<div class="flex flex-wrap pt-5">
  <div class="w-full flex px-4">
    <div class="px-4">
      Select Groups or group of groups to which messages were sent
    </div>
    <div class="relative lg:w-4/12 mb-3 mr-5">
      <GroupSelect bind:selectedGroups {fromId} />
    </div>
    <div class="relative lg:w-4/12 mb-3 mr-5">
      <GroupOfGroupSelect
        bind:selectedWhatsAppGroupOfGroups={groupOfGroups}
        {fromId}
      />
    </div>
    <div class="relative lg:w-4/12 mb-3">
      <ContactSelect {fromId} bind:selectedWhatsAppContacts />
    </div>
  </div>

  <div class="w-full flex lg:w-12/12 px-4">
    <div class="lg:w-4/12 mr-5">
      <div>Select Range</div>
      <div class="relative mb-3">
        <Flatpickr
          {options}
          bind:value={selectedRange}
          on:change={handleChange}
          name="date"
          class="w-full px-3 py-3 !h-10 text-gray-700 bg-white rounded text-sm"
        />
      </div>
    </div>
    <div class="lg:w-4/12">
      <div>Select message types</div>
      <div class="relative mb-3">
        <TypesSelect bind:selectedTypes />
      </div>
    </div>
  </div>
  <div>
    Read Rate - {readRate}%
  </div>
</div>
<div class="flex flex-wrap pt-5">
  {#if analyticsData.length !== 0}
    <CardBarChart data={analyticsData} />
  {/if}
</div>
