<script>
  import { onMount } from "svelte";
  // library that creates chart objects in page
  import Chart from "chart.js/auto";
  export let id = "line-chart";
  export let data = [];

  $: convertedData = data.reduce((acc, current) => {
    const existingItem = acc.find(
      (item) => item.messageId === current.messageId
    );
    if (existingItem) {
      existingItem.votes.push({
        sender: {
          user: current.voterSerializedId.split("@")[0],
          server: "lid",
          _serialized: `${current.voterSerializedId.split("@")[0]}@lid`,
        },
        timestamp: new Date(current.createdAt).getTime(),
        selectedOptions: [
          {
            name: current.vote,
            localId: 0,
          },
        ],
      });
    } else {
      acc.push({
        messageId: current.messageId,
        body: current.body,
        chatId: current.chatId,
        votes: [
          {
            sender: {
              user: current.voterSerializedId.split("@")[0],
              server: "lid",
              _serialized: `${current.voterSerializedId.split("@")[0]}@lid`,
            },
            timestamp: new Date(current.createdAt).getTime(),
            selectedOptions: [
              {
                name: current.vote,
                localId: 0,
              },
            ],
          },
        ],
        createdAt: current.createdAt,
        updatedAt: current.updatedAt,
      });
    }
    return acc;
  }, []);
  let myChart;
  let labels = [];
  let datasets;

  function updateChart() {
    myChart.data.labels = labels;
    myChart.data.datasets = datasets;
    myChart.update();
  }

  $: labels = [...new Set(convertedData.map((item) => item.body))];

  $: allVotesPerLabel = convertedData.reduce((acc, curr) => {
    if (acc[curr.body]) {
      acc[curr.body].push(...curr.votes);
    } else {
      acc[curr.body] = curr.votes || [];
    }
    return acc;
  }, {});

  $: allVotesPerLabelSelectedOptionsCount = Object.keys(
    allVotesPerLabel
  ).reduce((acc, curr) => {
    acc[curr] = {
      label: curr,
      data: allVotesPerLabel[curr].reduce((acc, curr) => {
        if (curr.selectedOptions) {
          curr.selectedOptions.filter(Boolean).forEach((option) => {
            if (acc[option.name]) {
              acc[option.name]++;
            } else {
              acc[option.name] = 1;
            }
          });
        }
        return acc;
      }, {}),
    };
    return acc;
  }, {});

  $: allSelectedOptionsPerLabel = Object.keys(
    allVotesPerLabelSelectedOptionsCount
  ).reduce((acc, curr) => {
    acc[curr] = Object.keys(allVotesPerLabelSelectedOptionsCount[curr].data);
    return acc;
  }, {});

  function randomColor() {
    return `#${Math.floor(Math.random() * 16777215).toString(16)}`;
  }

  $: {
    datasets = Object.keys(allSelectedOptionsPerLabel).reduce((acc, curr) => {
      const value = allSelectedOptionsPerLabel[curr];
      acc.push(
        ...value.map((item) => ({
          label: `${curr} - ${item}`,
          optionName: item,
          pollName: curr,
          backgroundColor: randomColor(),
          // borderColor: "#4c51bf",
          data: labels.map((label) => {
            return allVotesPerLabelSelectedOptionsCount[label].data[item] || 0;
          }),
          fill: false,
        }))
      );
      return acc;
    }, []);
    setTimeout(() => {
      updateChart();
    }, 0);
  }

  // init chart
  onMount(async () => {
    var config = {
      type: "bar",
      scaleFontColor: "#FFFFFF",
      color: "#FFFFFF",
      data: {
        labels,
        datasets,
      },
    };
    config = {
      type: "bar",
      data: convertedData,
      options: {
        plugins: {
          title: {
            display: true,
            text: "Poll data",
          },
        },
        responsive: true,
        scales: {
          x: {
            stacked: true,
          },
          y: {
            stacked: true,
          },
        },
      },
    };
    var ctx = document.getElementById(id).getContext("2d");
    myChart = new Chart(ctx, config);
  });
</script>

<div
  class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded bg-blueGray-700"
>
  <div class="rounded-t mb-0 px-4 py-3 bg-transparent">
    <div class="flex flex-wrap items-center">
      <div class="relative w-full max-w-full flex-grow flex-1">
        <h6 class="uppercase text-blueGray-100 mb-1 text-xs font-semibold">
          Overview
        </h6>
        <h2 class="text-white text-xl font-semibold">Message statistics</h2>
      </div>
    </div>
  </div>
  <div class="p-4 flex-auto">
    <!-- Chart -->
    <div class="relative">
      <canvas {id} />
    </div>
  </div>
</div>
