<script>
  import apiInstance from "helpers/api.js";
  import Select from "svelte-select";

  export let selectedWhatsAppGroupOfGroups = null;

  export let fromId = null;
  let getAllGroupOfGroupsLoading = false;
  let groupOfGroups = [];
  async function getAllGroupOfGroups(params) {
    if (getAllGroupOfGroupsLoading) return;
    getAllGroupOfGroupsLoading = true;
    try {
      const { data } = await apiInstance.listWhatsAppGroupOfGroups(params);
      groupOfGroups = data.map((item) => ({
        label: item.name,
        value: item._id,
        data: item,
      }));
    } catch (error) {
      console.error(error);
    }
    getAllGroupOfGroupsLoading = false;
  }
  $: {
    if (fromId) {
      groupOfGroups = [];
      getAllGroupOfGroups({
        fromId,
      });
    }
  }
</script>

<Select items={groupOfGroups} bind:value={selectedWhatsAppGroupOfGroups}>
  <div class="empty" slot="empty">No options</div>
</Select>
