<script>
  // core components
  export let header;
</script>

<!-- Navbar -->
<nav
  class="absolute top-0 left-0 w-full z-10 bg-transparent md:flex-row md:flex-nowrap md:justify-start flex items-center p-4"
>
  <div
    class="w-full mx-autp items-center flex justify-between md:flex-nowrap flex-wrap md:px-10 px-4"
  >
    <!-- Brand -->
    <div
      class="text-white text-sm uppercase hidden lg:inline-block font-semibold"
    >
      {header}
    </div>
  </div>
</nav>
<!-- End Navbar -->
