<script>
  import apiInstance from "helpers/api.js";
  import { onMount } from "svelte";
  import { failure, success } from "utils/toastUtils.js";
  import GroupOfGroupsModal from "components/Modals/GroupOfGroupsModal.svelte";

  // can be one of light or dark
  export let color = "light";
  let showModal = false;
  let modalData = null;
  let groupOfGroups = [];

  function toggleModal(data) {
    showModal = !showModal;
    modalData = data;
    if (!showModal) {
      getAllGroupOfGroups();
    }
  }

  let getAllGroupOfGroupsLoading = false;

  async function getAllGroupOfGroups() {
    if (getAllGroupOfGroupsLoading) return;
    getAllGroupOfGroupsLoading = true;
    try {
      const { data } = await apiInstance.listWhatsAppGroupOfGroups();
      groupOfGroups = data;
    } catch (error) {
      console.error(error);
    }
    getAllGroupOfGroupsLoading = false;
  }

  onMount(() => {
    getAllGroupOfGroups();
  });

  let deleting = {};
  async function onDelete(id) {
    deleting[id] = true;
    if (!confirm("Are you sure you want to delete this Group of group?")) {
      return;
    }
    try {
      await apiInstance.deleteWhatsAppGroupOfGroups({ id });

      await getAllGroupOfGroups();

      success("Group of group deleted successfully");
    } catch (error) {
      console.error(error);
      failure("Error in deleting Group of group");
    }
    deleting[id] = false;
  }
</script>

<div
  class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded {color ===
  'light'
    ? 'bg-white'
    : 'bg-black text-white'}"
>
  <div class="rounded-t mb-0 px-4 py-3 border-0">
    <div class="flex flex-wrap items-center">
      <div
        class="relative w-full px-4 max-w-full flex-grow flex-1 flex justify-between"
      >
        <h3
          class="font-semibold text-lg {color === 'light'
            ? 'text-blueGray-700'
            : 'text-white'}"
        >
          Group of Groups
        </h3>
        <button
          class="bg-black text-white active:bg-black font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
          type="button"
          on:click={() => toggleModal()}>Add Group Of Groups</button
        >
        {#if showModal}
          <GroupOfGroupsModal {toggleModal} entity={modalData} />
        {/if}
      </div>
    </div>
  </div>
  <div class="block w-full overflow-x-auto">
    <!-- Projects table -->
    <table class="items-center w-full bg-transparent border-collapse">
      <thead>
        <tr>
          <td
            class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left {color ===
            'light'
              ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
              : 'bg-black-700 text-green-200 border-red-600'}"
          >
            Group Of Groups Name
          </td>
          <td
            class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left {color ===
            'light'
              ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
              : 'bg-black-700 text-green-200 border-red-600'}"
          >
            WhatsApp Phone Number
          </td>
          <th
            class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left {color ===
            'light'
              ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
              : 'bg-black-700 text-green-200 border-red-600'}"
          >
            Groups
          </th>
          <th
            class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left {color ===
            'light'
              ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
              : 'bg-black-700 text-green-200 border-red-600'}"
          >
            Update
          </th>
          <th
            class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left {color ===
            'light'
              ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
              : 'bg-black-700 text-green-200 border-red-600'}"
          >
            Delete
          </th>
        </tr>
      </thead>
      <tbody>
        {#if groupOfGroups.length === 0}
          <tr>
            <td
              class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
              colspan="4"
            >
              No group of groups found
            </td>
          </tr>
        {/if}
        {#each groupOfGroups as groupOfGroup (groupOfGroup._id)}
          <tr>
            <td
              class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
            >
              {groupOfGroup?.name ?? ""}
            </td>
            <td
              class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
            >
              {groupOfGroup?.fromId ?? ""}
            </td>
            <td
              class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
            >
              {#each groupOfGroup?.groups as group, i (i)}
                <span
                  class="inline-flex items-center mx-2 px-3 py-1 bg-gray-200 rounded-full text-sm font-semibold text-gray-700"
                >
                  <span class="mr-2">{group.name}</span>
                </span>
              {/each}
            </td>
            <td
              class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
            >
              <!-- //loader -->
              <button
                class="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150 disabled:opacity-50"
                type="button"
                on:click={() => toggleModal(groupOfGroup)}
              >
                Update
              </button>
            </td>
            <td>
              <button
                class="bg-black text-white active:bg-black-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150 disabled:opacity-50"
                type="button"
                disabled={deleting[groupOfGroup._id]}
                on:click={() => onDelete(groupOfGroup._id)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 128 128"
                  width="16px"
                  height="16px"
                  fill="currentColor"
                  ><path
                    d="M 49 1 C 47.34 1 46 2.34 46 4 C 46 5.66 47.34 7 49 7 L 79 7 C 80.66 7 82 5.66 82 4 C 82 2.34 80.66 1 79 1 L 49 1 z M 24 15 C 16.83 15 11 20.83 11 28 C 11 35.17 16.83 41 24 41 L 101 41 L 101 104 C 101 113.37 93.37 121 84 121 L 44 121 C 34.63 121 27 113.37 27 104 L 27 52 C 27 50.34 25.66 49 24 49 C 22.34 49 21 50.34 21 52 L 21 104 C 21 116.68 31.32 127 44 127 L 84 127 C 96.68 127 107 116.68 107 104 L 107 40.640625 C 112.72 39.280625 117 34.14 117 28 C 117 20.83 111.17 15 104 15 L 24 15 z M 24 21 L 104 21 C 107.86 21 111 24.14 111 28 C 111 31.86 107.86 35 104 35 L 24 35 C 20.14 35 17 31.86 17 28 C 17 24.14 20.14 21 24 21 z M 50 55 C 48.34 55 47 56.34 47 58 L 47 104 C 47 105.66 48.34 107 50 107 C 51.66 107 53 105.66 53 104 L 53 58 C 53 56.34 51.66 55 50 55 z M 78 55 C 76.34 55 75 56.34 75 58 L 75 104 C 75 105.66 76.34 107 78 107 C 79.66 107 81 105.66 81 104 L 81 58 C 81 56.34 79.66 55 78 55 z"
                  /></svg
                >
              </button>
            </td>
          </tr>
        {/each}
      </tbody>
    </table>
  </div>
</div>
