<script>
  import apiInstance from "helpers/api.js";
  import { onMount } from "svelte";
  import Select from "svelte-select";

  export let selectedWhatsAppProfile = null;
  let getListWhatsAppProfiles = false;
  let whatsappProfiles = [];
  onMount(() => {
    getWhatsAppProfiles();
  });

  async function getWhatsAppProfiles() {
    if (getListWhatsAppProfiles) return;
    getListWhatsAppProfiles = true;
    try {
      const { data } = await apiInstance.listWhatsAppProfiles({
        filter: {
          state: "INITIALIZED",
        },
      });
      whatsappProfiles = data.map((item) => ({
        label: `${item.clientInfo.me.user} - ${item.clientInfo.pushName}`,
        value: item.id,
        data: item,
      }));
      console.log(whatsappProfiles);
    } catch (error) {
      console.error(error);
    }
    getListWhatsAppProfiles = false;
  }
</script>

<Select items={whatsappProfiles} bind:value={selectedWhatsAppProfile}>
  <div class="empty" slot="empty">No options</div>
</Select>
