<script>
  import Login from "views/auth/Login.svelte";
</script>

<div>
  <main>
    <section class="relative w-full h-full py-40 min-h-screen">
      <div
        class="absolute top-0 w-full h-full bg-blueGray-800 bg-no-repeat bg-full"
      />
      <Login />
    </section>
  </main>
</div>
