<script>
  import RegularModal from "./RegularModal.svelte";

  export let data = []; // {messageSendResult, id, contact, ack}
  export let contacts = [];
  export let toggleModal = () => {};

  console.log(data, contacts);

  function listDuplicates(contacts) {
    let duplicates = [];
    let seen = new Set();
    for (let i = 0; i < contacts.length; i++) {
      let contact = contacts[i];
      if (seen.has(contact.value)) {
        duplicates.push(contact);
      } else {
        seen.add(contact.value);
      }
    }
    return duplicates;
  }

  $: duplicates = listDuplicates(contacts);
</script>

<RegularModal modalTitle="Message acknowledgements" {toggleModal}>
  <div class="min-w-[50vw] mb-4">
    <div class="mt-4 overflow-auto max-h-[600px]">
      <!-- // loop over data -->
      {#each data as sentAck}
        <div class="flex justify-between items-center px-10">
          <div class="my-4 text-blueGray-500 text-lg leading-relaxed">
            {contacts.find((c) => c.value === sentAck.contact)?.label}{" "}
          </div>
          <div>
            {sentAck?.messageSendResult}
          </div>
        </div>
      {/each}

      {#if duplicates.length > 0}
        <div class="px-5 mt-6 text-black text-lg">
          Duplicates to which no message was sent
        </div>
        <div class="flex flex-col px-10">
          {#each duplicates as duplicate}
            <div class="my-4 text-blueGray-500 text-lg leading-relaxed">
              {duplicate.label}
            </div>
          {/each}
        </div>
      {/if}
    </div>
  </div>
</RegularModal>
