<script>
  import { useNavigate, useLocation } from "svelte-navigator";
  import { userStore } from "store/svelteStore.js";

  const navigate = useNavigate();
  const location = useLocation();

  $: if (!$userStore?.uid) {
    navigate("/auth/login", {
      state: { from: $location.pathname },
      replace: true,
    });
  }
</script>

{#if $userStore?.uid}
  <slot />
{/if}
