<script>
  import apiInstance from "helpers/api.js";
  import { getGroupLink } from "utils/urlUtils.js";

  let name = "";
  let email = "";
  let phone = "";
  let errorMessage = "";

  $: isDisabled =
    name.trim().length === 0 ||
    email.trim().length === 0 ||
    phone.trim().length === 0;

  let loading = false;

  function createLead({ name, email, phone, url, id }) {
    return apiInstance.createLead({ name, email, phone, url, id });
  }

  function submitForm(e) {
    if (isDisabled) return;
    if (loading) return;

    loading = true;
    e.preventDefault();
    // get id from url
    const urlParams = new URLSearchParams(window.location.search);
    const id = urlParams.get("id");
    if (!id) {
      errorMessage = "Invalid url";
      return;
    }
    // call backend api

    return fetch(getGroupLink(id))
      .then((res) => res.json())
      .then((result) => {
        if (!result?.url) throw new Error(result);
        const url = result.url;
        return createLead({ name, email, phone, url, id }).then(() => {
          window.location.replace(url);
        });
      })
      .catch((err) => {
        console.error(err);
        errorMessage = "Something went wrong, please try again later.";
      })
      .then(() => {
        loading = false;
      });
  }
</script>

<div class="bg-white dark:bg-gray-900">
  <div class="flex justify-center h-screen">
    <div
      class="hidden lg:block lg:w-2/3"
      style="background-image: url(https://uploads-ssl.webflow.com/64bd17c976f02c3a8c8e76ec/64de252c2170d4c85528fea2_Frame%201160444801.png)"
    >
      <div class="flex items-center h-full px-20 bg-gray-900 bg-opacity-40" />
    </div>

    <div class="flex items-center w-full max-w-md px-6 mx-auto lg:w-2/6">
      <div class="flex-1">
        <div class="text-center">
          <h2 class="text-4xl font-bold text-center gradient-text">
            Finance with Sharan
          </h2>

          <p class="mt-3 text-gray-500 dark:text-gray-300">
            Join to access your Community
          </p>
        </div>

        <div class="mt-8">
          <form on:submit={submitForm}>
            <div>
              <label
                for="name"
                class="block mb-2 text-sm text-gray-600 dark:text-gray-200"
                >Name</label
              >
              <input
                type="text"
                name="name"
                bind:value={name}
                placeholder="Sharan Hegde"
                required
                class="block w-full px-4 py-2 mt-2 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-md dark:placeholder-gray-600 dark:bg-gray-900 dark:text-gray-300 dark:border-gray-700 focus:border-blue-400 dark:focus:border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring focus:ring-opacity-40"
              />
            </div>

            <div class="mt-6">
              <label
                for="email"
                class="block mb-2 text-sm text-gray-600 dark:text-gray-200"
                >Email Address</label
              >
              <input
                type="email"
                name="email"
                bind:value={email}
                required
                placeholder="example@example.com"
                class="block w-full px-4 py-2 mt-2 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-md dark:placeholder-gray-600 dark:bg-gray-900 dark:text-gray-300 dark:border-gray-700 focus:border-blue-400 dark:focus:border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring focus:ring-opacity-40"
              />
            </div>

            <div class="mt-6">
              <label
                for="phone"
                class="block mb-2 text-sm text-gray-600 dark:text-gray-200"
                >Whatsapp Number</label
              >
              <input
                type="phone"
                name="phone"
                bind:value={phone}
                minlength="10"
                maxlength="10"
                required
                placeholder="1234567890"
                class="block w-full px-4 py-2 mt-2 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-md dark:placeholder-gray-600 dark:bg-gray-900 dark:text-gray-300 dark:border-gray-700 focus:border-blue-400 dark:focus:border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring focus:ring-opacity-40"
              />
            </div>
            {#if errorMessage}
              <div class="mt-6">
                <p class="text-green-500">{errorMessage}</p>
              </div>
            {/if}

            <div class="mt-6">
              <button
                class="w-full px-4 py-2 tracking-wide text-white transition-colors duration-200 transform bg-blue-500 rounded-md hover:bg-blue-400 focus:outline-none focus:bg-blue-400 focus:ring focus:ring-blue-300 focus:ring-opacity-50 {isDisabled ||
                loading
                  ? 'opacity-50 cursor-not-allowed'
                  : ''}"
                disabled={isDisabled || loading}
              >
                Join
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>

<style>
  .gradient-text {
    /* Fallback: Set a background color. */
    background-color: red;

    /* Create the gradient. */
    background-image: linear-gradient(45deg, #f3ec78, #af4261);

    /* Set the background size and repeat properties. */
    background-size: 100%;
    background-repeat: repeat;

    /* Use the text as a mask for the background. */
    /* This will show the gradient as a text color rather than element bg. */
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    -moz-background-clip: text;
    -moz-text-fill-color: transparent;
  }
</style>
