// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";
import { isLocal } from "./envUtils.js";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = isLocal()
  ? {
      apiKey: "AIzaSyCQC053y7k728HV6x18dIkfV3ovcIr5rf8",
      authDomain: "url-shortener-test-91be6.firebaseapp.com",
      projectId: "url-shortener-test-91be6",
      storageBucket: "url-shortener-test-91be6.appspot.com",
      messagingSenderId: "799626470599",
      appId: "1:799626470599:web:e6cf4b4cb024896cd78ae2",
    }
  : {
      apiKey: "AIzaSyDCq7L8Sp8yNEBDxfTZyWoPlQWamRVPk3c",
      authDomain: "url-shortener-8ec5a.firebaseapp.com",
      projectId: "url-shortener-8ec5a",
      storageBucket: "url-shortener-8ec5a.appspot.com",
      messagingSenderId: "932224540622",
      appId: "1:932224540622:web:4cac6c2488b5c5cf0e959f",
    };

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);

export const firestore = getFirestore(firebaseApp);
export const auth = getAuth(firebaseApp);
export const storage = getStorage(firebaseApp);

export function getFirebaseProjectId() {
  return firebaseApp.options.authDomain.split(".")[0];
}

export default firebaseApp;
