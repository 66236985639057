import { get } from "svelte/store";
import axios from "./axiosInstance";
import { userStore } from "store/svelteStore.js";
import { isLocal } from "utils/envUtils.js";
import { baseUrls } from "constants/baseUrls.js";

function getBaseUrl() {
  if (isLocal()) {
    return "http://127.0.0.1:8080";
  }
  const user = get(userStore);
  const baseUrl = baseUrls[user?.uid] || baseUrls["MoneyMonitors"];
  return baseUrl;
}

function API() {
  return {
    initiateWhatsAppProfile: function () {
      return axios.post(`${getBaseUrl()}/api/whatsapp/init`, {});
    },
    deleteWhatsAppProfile: function (params) {
      return axios.delete(`${getBaseUrl()}/api/whatsapp/remove/${params.id}`);
    },
    listWhatsAppProfiles: function (params) {
      return axios.get(`${getBaseUrl()}/api/whatsapp/list`, {}, { params });
    },
    listWhatsAppContacts: function (params) {
      return axios.get(
        `${getBaseUrl()}/api/whatsapp/contacts/list`,
        {},
        { params }
      );
    },
    listWhatsAppGroupOfGroups: function (params) {
      return axios.get(
        `${getBaseUrl()}/api/whatsapp/group-of-groups/list`,
        {},
        { params }
      );
    },
    listWhatsAppGroups: function (params) {
      return axios.get(
        `${getBaseUrl()}/api/whatsapp/groups/list`,
        {},
        { params }
      );
    },
    listReadRate: function (params) {
      return axios.post(
        `${getBaseUrl()}/api/whatsApp/analytics/read-rates`,
        params,
        {}
      );
    },
    updateWhatsAppGroupOfGroups: function (params) {
      return axios.post(
        `${getBaseUrl()}/api/whatsapp/group-of-groups/update`,
        params
      );
    },
    deleteWhatsAppGroupOfGroups: function (params) {
      return axios.post(
        `${getBaseUrl()}/api/whatsapp/group-of-groups/remove/`,
        params
      );
    },
    sendWhatsAppMessage: function (params) {
      return axios.post(`${getBaseUrl()}/api/whatsapp/send-message`, params);
    },
    createWhatsAppGroupLink: function (params) {
      return axios.post(`${getBaseUrl()}/api/whatsapp/group-link`, params);
    },
    createLead: function (params) {
      return axios.post(`${getBaseUrl()}/api/leads/create`, params);
    },
    updateContacts: function (params) {
      return axios.post(`${getBaseUrl()}/api/whatsapp/contacts/update`, params);
    },
    startSession: function (params) {
      return axios.post(`${getBaseUrl()}/api/whatsapp/session/start`, params);
    },
    stopSession: function (params) {
      return axios.post(`${getBaseUrl()}/api/whatsapp/session/stop`, params);
    },
    listWhatsAppMessages: function (params) {
      return axios.get(
        `${getBaseUrl()}/api/whatsapp/messages/list`,
        {},
        { params }
      );
    },
    listWhatsAppVotes: function (params) {
      return axios.get(
        `${getBaseUrl()}/api/whatsapp/votes/list`,
        {},
        { params }
      );
    },
    updateAnalytics: function (params) {
      return axios.post(
        `${getBaseUrl()}/api/whatsapp/analytics/update`,
        params
      );
    },
    getWhatsAppMessageAnalytics: function (params) {
      return axios.get(
        `${getBaseUrl()}/api/whatsapp/analytics/message`,
        {},
        { params }
      );
    },
    getWhatsAppGroupAndContactsAnalytics: function (params) {
      return axios.get(
        `${getBaseUrl()}/api/whatsapp/analytics/group-and-contacts`,
        {},
        { params }
      );
    },
    listScheduledMessages: function (params) {
      return axios.get(
        `${getBaseUrl()}/api/whatsapp/scheduled-messages/list`,
        {},
        { params }
      );
    },
    deleteScheduledMessage: function (params) {
      return axios.delete(
        `${getBaseUrl()}/api/whatsapp/scheduled-messages/remove/${params.id}`
      );
    },
    listDashboardMessages: function (params) {
      return axios.get(
        `${getBaseUrl()}/api/whatsapp/dashboard-messages/list`,
        {},
        { params }
      );
    },
  };
}

const apiInstance = API();
export default apiInstance;
