<script>
  import { link, useLocation } from "svelte-navigator";
  import { auth } from "utils/firebase.js";

  const location = useLocation();

  let collapseShow = "hidden";

  function toggleCollapseShow(classes) {
    collapseShow = classes;
  }
</script>

<nav
  class="md:left-0 md:block md:fixed md:top-0 md:bottom-0 md:overflow-y-auto md:flex-row md:flex-nowrap md:overflow-hidden shadow-xl bg-white flex flex-wrap items-center justify-between relative md:w-64 z-10 py-4 px-6"
>
  <div
    class="md:flex-col md:items-stretch md:min-h-full md:flex-nowrap px-0 flex flex-wrap items-center justify-between w-full mx-auto"
  >
    <button
      class="cursor-pointer text-black opacity-50 md:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent"
      type="button"
      on:click={() => toggleCollapseShow("bg-white m-2 py-3 px-6")}
    >
      <i class="fas fa-bars" />
    </button>
    <a
      class="md:block text-left md:pb-2 text-blueGray-600 mr-0 inline-block whitespace-nowrap text-sm uppercase font-bold p-4 px-0"
      href="/"
    >
      WhatsApp Manager
    </a>
    <div
      class="md:flex md:flex-col md:items-stretch md:opacity-100 md:relative md:mt-4 md:shadow-none shadow absolute top-0 left-0 right-0 z-40 overflow-y-auto overflow-x-hidden h-auto items-center flex-1 rounded {collapseShow}"
    >
      <div
        class="md:min-w-full md:hidden block pb-4 mb-4 border-b border-solid border-blueGray-200"
      >
        <div class="flex flex-wrap">
          <div class="w-6/12">
            <a
              class="md:block text-left md:pb-2 text-blueGray-600 mr-0 inline-block whitespace-nowrap text-sm uppercase font-bold p-4 px-0"
              href="/"
              use:link
            >
              WhatsApp Manager
            </a>
          </div>
          <div class="w-6/12 flex justify-end">
            <button
              type="button"
              class="cursor-pointer text-black opacity-50 md:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent"
              on:click={() => toggleCollapseShow("hidden")}
            >
              <i class="fas fa-times" />
            </button>
          </div>
        </div>
      </div>
      <form class="mt-6 mb-4 md:hidden">
        <div class="mb-3 pt-0">
          <input
            type="text"
            placeholder="Search"
            class="border-0 px-3 py-2 h-12 border border-solid border-blueGray-500 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-base leading-snug shadow-none outline-none focus:outline-none w-full font-normal"
          />
        </div>
      </form>

      <hr class="my-4 md:min-w-full" />
      <h6
        class="md:min-w-full text-blueGray-500 text-xs uppercase font-bold block pt-1 pb-4 no-underline"
      >
        Main
      </h6>

      <ul class="md:flex-col md:min-w-full flex flex-col list-none">
        <li class="items-center">
          <a
            href="/"
            use:link
            class="text-xs uppercase py-3 font-bold block {$location.pathname ===
            '/'
              ? 'text-green-500 hover:text-green-600'
              : 'text-blueGray-700 hover:text-blueGray-500'}"
          >
            <i
              class="fas fa-tv mr-2 text-sm {$location.pathname === '/'
                ? 'opacity-75'
                : 'text-blueGray-300'}"
            />
            Send Group Message
          </a>
        </li>

        <li class="items-center">
          <a
            href="/whatsapp-profiles"
            use:link
            class="text-xs uppercase py-3 font-bold block {$location.pathname ===
            '/whatsapp-profiles'
              ? 'text-green-500 hover:text-green-600'
              : 'text-blueGray-700 hover:text-blueGray-500'}"
          >
            <i
              class="fas fa-tv mr-2 text-sm {$location.pathname ===
              '/whatsapp-profiles'
                ? 'opacity-75'
                : 'text-blueGray-300'}"
            />
            WhatsApp Profiles
          </a>
        </li>

        <li class="items-center">
          <a
            href="/analytics"
            use:link
            class="text-xs uppercase py-3 font-bold block {$location.pathname ===
            '/analytics'
              ? 'text-green-500 hover:text-green-600'
              : 'text-blueGray-700 hover:text-blueGray-500'}"
          >
            <i
              class="fas fa-tv mr-2 text-sm {$location.pathname === '/analytics'
                ? 'opacity-75'
                : 'text-blueGray-300'}"
            />
            WhatsApp Analytics
          </a>
        </li>

        <li class="items-center">
          <a
            href="/group-of-groups"
            use:link
            class="text-xs uppercase py-3 font-bold block {$location.pathname ===
            '/group-of-groups'
              ? 'text-green-500 hover:text-green-600'
              : 'text-blueGray-700 hover:text-blueGray-500'}"
          >
            <i
              class="fas fa-tv mr-2 text-sm {$location.pathname ===
              '/group-of-groups'
                ? 'opacity-75'
                : 'text-blueGray-300'}"
            />
            Group of groups
          </a>
        </li>

        <li class="items-center">
          <a
            href="/group-link"
            use:link
            class="text-xs uppercase py-3 font-bold block {$location.pathname ===
            '/group-link'
              ? 'text-green-500 hover:text-green-600'
              : 'text-blueGray-700 hover:text-blueGray-500'}"
          >
            <i
              class="fas fa-tv mr-2 text-sm {$location.pathname ===
              '/group-link'
                ? 'opacity-75'
                : 'text-blueGray-300'}"
            />
            Group link
          </a>
        </li>

        <li class="items-center">
          <a
            href="/url-generator"
            use:link
            class="text-xs uppercase py-3 font-bold block {$location.pathname ===
            '/url-generator'
              ? 'text-green-500 hover:text-green-600'
              : 'text-blueGray-700 hover:text-blueGray-500'}"
          >
            <i
              class="fas fa-tv mr-2 text-sm {$location.pathname ===
              '/url-generator'
                ? 'opacity-75'
                : 'text-blueGray-300'}"
            />
            Url Generator
          </a>
        </li>
      </ul>

      <h6
        class="md:min-w-full text-blueGray-500 text-xs uppercase font-bold block pt-1 pb-4 no-underline"
      >
        Settings
      </h6>

      <ul class="md:flex-col md:min-w-full flex flex-col list-none">
        <li class="items-center">
          <div
            on:click={() => {
              if (!confirm("Are you sure you want to logout?")) {
                return;
              }
              auth.signOut();
            }}
            role="button"
            class="cursor-pointer text-xs uppercase py-3 font-bold block text-blueGray-700 hover:text-blueGray-500"
          >
            Logout
          </div>
        </li>
      </ul>
    </div>
  </div>
</nav>
