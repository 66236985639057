import App from "./App.svelte";
import "../index.css";
import "@fortawesome/fontawesome-free/css/all.css";
import "@fortawesome/fontawesome-free/js/all.js";
import firebaseApp from "utils/firebase.js";

const app = new App({
  target: document.body,
  props: {},
});

export default app;
