<script>
  import { onMount } from "svelte";
  // library that creates chart objects in page
  import Chart from "chart.js/auto";
  export let id = "line-chart";
  export let data = [];

  let myChart;
  let labels = [];
  let datasets;

  function updateChart() {
    myChart.data.labels = labels;
    myChart.data.datasets = datasets;
    myChart.update();
  }

  $: {
    labels = data.map((item) => {
      const date = new Date(item.time_interval);
      const formatter = new Intl.DateTimeFormat("en-US", {
        dateStyle: "short",
      });
      const formattedDate = formatter.format(date);
      return formattedDate;
    });
    datasets = [
      {
        label: "Message count",
        backgroundColor: "#4c51bf",
        borderColor: "#4c51bf",
        data: data.map((item) => item.message_count),
        fill: false,
      },
    ];
    setTimeout(() => {
      updateChart();
    }, 0);
  }

  // init chart
  onMount(async () => {
    var config = {
      type: "bar",
      scaleFontColor: "#FFFFFF",
      color: "#FFFFFF",
      data: {
        labels,
        datasets,
      },
    };
    var ctx = document.getElementById(id).getContext("2d");
    myChart = new Chart(ctx, config);
  });
</script>

<div
  class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded bg-blueGray-700"
>
  <div class="rounded-t mb-0 px-4 py-3 bg-transparent">
    <div class="flex flex-wrap items-center">
      <div class="relative w-full max-w-full flex-grow flex-1">
        <h6 class="uppercase text-blueGray-100 mb-1 text-xs font-semibold">
          Overview
        </h6>
        <h2 class="text-white text-xl font-semibold">Message statistics</h2>
      </div>
    </div>
  </div>
  <div class="p-4 flex-auto">
    <!-- Chart -->
    <div class="relative">
      <canvas {id} />
    </div>
  </div>
</div>
