<script>
  import RegularModal from "components/Modals/RegularModal.svelte";
  import apiInstance from "helpers/api.js";
  import QRCode from "qrcode";
  import LoadingIndicator from "components/Loading/LoadingIndicator.svelte";
  import { firestore } from "utils/firebase.js";
  import { doc, onSnapshot } from "firebase/firestore";
  import { onMount, tick } from "svelte";
  import Switch from "svelte-switch";
  import { failure, success } from "utils/toastUtils.js";

  let stateColorMap = {
    INITIALIZED: "text-emerald-500",
    READY: "text-yellow-500",
    PENDING: "text-blue-500",
    LOADING: "text-blue-500",
    AUTHENTICATED: "text-blue-500",
    QR_GENERATED: "text-blueGray-500",
    ERROR: "text-green-500",
  };

  // can be one of light or dark
  export let color = "light";
  let showModal = false;
  let modalData = null;
  let whatsappProfiles = [];

  function toggleModal() {
    showModal = !showModal;
    modalData = null;
  }

  let initiateWhatsAppProfileLoading = false;
  let getAllWhatsAppProfilesLoading = false;
  let unsubscribe;

  $: {
    if (!showModal) {
      unsubscribe?.();
    }
  }

  $: {
    if (
      ["INITIALIZED", "READY", "PENDING", "QR_GENERATED", "ERROR"].includes(
        modalData?.data?.state
      )
    ) {
      getAllWhatsAppProfiles();
    }
  }

  async function getAllWhatsAppProfiles() {
    if (getAllWhatsAppProfilesLoading) return;
    getAllWhatsAppProfilesLoading = true;
    try {
      const { data } = await apiInstance.listWhatsAppProfiles();
      whatsappProfiles = data;
    } catch (error) {
      console.error(error);
    }
    getAllWhatsAppProfilesLoading = false;
  }

  onMount(() => {
    getAllWhatsAppProfiles();
  });

  async function initiateWhatsAppProfile() {
    if (initiateWhatsAppProfileLoading) return;
    initiateWhatsAppProfileLoading = true;
    try {
      const { data } = await apiInstance.initiateWhatsAppProfile();
      modalData = { data, status: "success" };
      console.log(data);
      const whatsAppStateDoc = doc(firestore, "whatsapp-state", data.id);
      unsubscribe = onSnapshot(
        whatsAppStateDoc,
        async (docSnapshot) => {
          const docData = docSnapshot.data();
          console.log(docData);
          modalData = {
            data: { ...modalData.data, ...docData },
            status: "success",
          };

          if (docData.state === "QR_GENERATED") {
            await tick();
            const canvas = document.getElementById("qr-code-canvas");
            QRCode.toCanvas(canvas, docData.qrCode, function (error) {
              if (error) console.error(error);
              console.log("success!");
            });
          }
        },
        (err) => {
          console.log(`Encountered error: ${err}`);
        }
      );
    } catch (error) {
      console.error(error);
      modalData = { error, status: "error" };
    }
    initiateWhatsAppProfileLoading = false;
  }

  let refetchingContacts = {};
  async function onRefetchContacts(id) {
    refetchingContacts[id] = true;
    try {
      const { data } = await apiInstance.updateContacts({ id });
      console.log(data);
      success("Contacts refetched successfully");
    } catch (error) {
      console.error(error);
      failure("Error in refetching contacts");
    }
    refetchingContacts[id] = false;
  }

  let refetchingAnalytics = {};
  async function onRefetchAnalytics(id) {
    refetchingAnalytics[id] = true;
    try {
      const { data } = await apiInstance.updateAnalytics({ id });
      console.log(data);
      success("Analytics refetched successfully");
    } catch (error) {
      console.error(error);
      failure("Error in refetching Analytics");
    }
    refetchingAnalytics[id] = false;
  }

  let deleting = {};
  async function onDelete(id) {
    deleting[id] = true;
    if (!confirm("Are you sure you want to delete this whatsapp profile?")) {
      return;
    }
    try {
      await apiInstance.deleteWhatsAppProfile({ id });

      await getAllWhatsAppProfiles();

      success("WhatsApp profile deleted successfully");
    } catch (error) {
      console.error(error);
      failure("Error in deleting whatsapp profile");
    }
    deleting[id] = false;
  }
  let switching = {};

  async function handleSwitchChange(e, id) {
    switching[id] = true;
    const { checked } = e.detail;
    try {
      if (checked) {
        await apiInstance.startSession({ id });
      } else {
        await apiInstance.stopSession({ id });
      }
      getAllWhatsAppProfiles();
      success("Session switched successfully");
    } catch (error) {
      console.error(error);
      failure("Error in switching session");
    }
    switching[id] = false;
  }

  function getWhatsAppProfileState(state) {
    return stateColorMap[state] ?? "text-green-500";
  }
</script>

<div
  class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded {color ===
  'light'
    ? 'bg-white'
    : 'bg-black text-white'}"
>
  <div class="rounded-t mb-0 px-4 py-3 border-0">
    <div class="flex flex-wrap items-center">
      <div
        class="relative w-full px-4 max-w-full flex-grow flex-1 flex justify-between"
      >
        <h3
          class="font-semibold text-lg {color === 'light'
            ? 'text-blueGray-700'
            : 'text-white'}"
        >
          Whatsapp profiles
        </h3>
        <button
          class="bg-black text-white active:bg-black font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
          type="button"
          on:click={toggleModal}>Add Profile</button
        >
        {#if showModal}
          <RegularModal
            modalTitle={modalData?.data?.state === "INITIALIZED"
              ? "WhatsApp Connected"
              : "Connect WhatsApp"}
            {toggleModal}
          >
            <div class="flex items-center justify-center p-2">
              {#if initiateWhatsAppProfileLoading}
                <LoadingIndicator />
              {:else if modalData}
                {#if modalData.status === "success"}
                  {#if modalData.data.state === "QR_GENERATED"}
                    <div class="flex flex-col items-center justify-center">
                      <canvas id="qr-code-canvas" class="w-64 h-64" />
                      <p class="text-center">
                        Scan the QR code above with your WhatsApp mobile app to
                        connect your WhatsApp account
                      </p>
                    </div>
                  {:else if modalData.data.state === "PENDING"}
                    <p class="text-center">
                      Creating a qr code for your WhatsApp account. Please wait
                    </p>
                  {:else if modalData.data.state === "LOADING"}
                    <p class="text-center">WhatsApp is loading. Please wait</p>
                  {:else if modalData.data.state === "AUTHENTICATED"}
                    <p class="text-center">
                      WhatsApp is authenticated. Please wait
                    </p>
                  {:else if modalData.data.state === "READY"}
                    <p class="text-center">
                      WhatsApp is connecting. Please wait
                    </p>
                  {:else if modalData.data.state === "INITIALIZED"}
                    <p class="text-center">
                      WhatsApp account with push name {modalData.data.clientInfo
                        .pushName} and number {modalData.data.clientInfo.me
                        .user} is now connected. Please close this modal and continue
                    </p>
                  {/if}
                {:else if modalData.status === "error"}
                  <p class="text-center">
                    {modalData.error.message}
                  </p>
                {/if}
              {:else}
                <button
                  class="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                  type="button"
                  on:click={initiateWhatsAppProfile}
                >
                  Initiate Connection
                </button>
              {/if}
            </div>
          </RegularModal>
        {/if}
      </div>
    </div>
  </div>
  <div class="block w-full overflow-x-auto">
    <!-- Projects table -->
    <table class="items-center w-full bg-transparent border-collapse">
      <thead>
        <tr>
          <td
            class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left {color ===
            'light'
              ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
              : 'bg-black-700 text-green-200 border-red-600'}"
          >
            WhatsApp Phone Number
          </td>
          <th
            class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left {color ===
            'light'
              ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
              : 'bg-black-700 text-green-200 border-red-600'}"
          >
            WhatsApp Push Name
          </th>
          <th
            class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left {color ===
            'light'
              ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
              : 'bg-black-700 text-green-200 border-red-600'}"
          >
            WhatsApp State
          </th>

          <th
            class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left {color ===
            'light'
              ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
              : 'bg-black-700 text-green-200 border-red-600'}"
          >
            WhatsApp Session
          </th>

          <th
            class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left {color ===
            'light'
              ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
              : 'bg-black-700 text-green-200 border-red-600'}"
          >
            Created at
          </th>
          <th
            class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left {color ===
            'light'
              ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
              : 'bg-black-700 text-green-200 border-red-600'}"
          >
            Refetch data
          </th>
          <th
            class="px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left {color ===
            'light'
              ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
              : 'bg-black-700 text-green-200 border-red-600'}"
          >
            Delete
          </th>
        </tr>
      </thead>
      <tbody>
        {#if whatsappProfiles.length === 0}
          <tr>
            <td
              class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
              colspan="4"
            >
              No whatsapp profiles found
            </td>
          </tr>
        {/if}
        {#each whatsappProfiles as whatsappProfile}
          <tr>
            <td
              class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
            >
              {whatsappProfile?.clientInfo?.me?.user ?? ""}
            </td>
            <td
              class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
            >
              {whatsappProfile?.clientInfo?.pushName ?? ""}
            </td>
            <td
              class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
            >
              <i
                class="fas fa-circle mr-2 {getWhatsAppProfileState(
                  whatsappProfile?.state
                )}"
              />
              {whatsappProfile?.state === "READY"
                ? "CONNECTING"
                : whatsappProfile?.state}
            </td>

            <td
              class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
            >
              {#if whatsappProfile.state === "INITIALIZED"}
                {#if switching[whatsappProfile.id] || whatsappProfile?.session === "STARTING"}
                  <div
                    class="inline-block h-2 w-2 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
                    role="status"
                  >
                    <span
                      class="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]"
                      >Loading...</span
                    >
                  </div>
                {:else}
                  <Switch
                    on:change={(e) => handleSwitchChange(e, whatsappProfile.id)}
                    checked={whatsappProfile?.session === "STARTED"}
                  />
                {/if}
              {/if}
            </td>

            <td
              class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
            >
              {whatsappProfile?.created?._seconds
                ? new Date(
                    whatsappProfile.created._seconds * 1000
                  ).toLocaleString("en-US", {
                    weekday: "short",
                    year: "numeric",
                    month: "short",
                    day: "numeric",
                    hour: "numeric",
                    minute: "numeric",
                  })
                : ""}
            </td>
            <td
              class="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"
            >
              <!-- //loader -->
              {#if whatsappProfile?.state === "INITIALIZED"}
                <button
                  class="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150 disabled:opacity-50"
                  type="button"
                  disabled={refetchingContacts[whatsappProfile.id]}
                  on:click={() => onRefetchContacts(whatsappProfile.id)}
                >
                  {#if refetchingContacts[whatsappProfile.id]}
                    <div
                      class="inline-block h-2 w-2 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
                      role="status"
                    >
                      <span
                        class="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]"
                        >Loading...</span
                      >
                    </div>
                  {/if}

                  {#if refetchingContacts[whatsappProfile.id]}
                    Refetching Contacts
                  {:else}
                    Refetch Contacts
                  {/if}
                </button>
              {/if}
              {#if whatsappProfile?.state === "INITIALIZED"}
                <button
                  class="bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150 disabled:opacity-50"
                  type="button"
                  disabled={refetchingAnalytics[whatsappProfile.id]}
                  on:click={() => onRefetchAnalytics(whatsappProfile.id)}
                >
                  {#if refetchingAnalytics[whatsappProfile.id]}
                    <div
                      class="inline-block h-2 w-2 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
                      role="status"
                    >
                      <span
                        class="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]"
                        >Loading...</span
                      >
                    </div>
                  {/if}

                  {#if refetchingAnalytics[whatsappProfile.id]}
                    Refetching Analytics
                  {:else}
                    Refetch Analytics
                  {/if}
                </button>
              {/if}
            </td>
            <td>
              <button
                class="bg-black text-white active:bg-black-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150 disabled:opacity-50"
                type="button"
                disabled={deleting[whatsappProfile.id]}
                on:click={() => onDelete(whatsappProfile.id)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 128 128"
                  width="16px"
                  height="16px"
                  fill="currentColor"
                  ><path
                    d="M 49 1 C 47.34 1 46 2.34 46 4 C 46 5.66 47.34 7 49 7 L 79 7 C 80.66 7 82 5.66 82 4 C 82 2.34 80.66 1 79 1 L 49 1 z M 24 15 C 16.83 15 11 20.83 11 28 C 11 35.17 16.83 41 24 41 L 101 41 L 101 104 C 101 113.37 93.37 121 84 121 L 44 121 C 34.63 121 27 113.37 27 104 L 27 52 C 27 50.34 25.66 49 24 49 C 22.34 49 21 50.34 21 52 L 21 104 C 21 116.68 31.32 127 44 127 L 84 127 C 96.68 127 107 116.68 107 104 L 107 40.640625 C 112.72 39.280625 117 34.14 117 28 C 117 20.83 111.17 15 104 15 L 24 15 z M 24 21 L 104 21 C 107.86 21 111 24.14 111 28 C 111 31.86 107.86 35 104 35 L 24 35 C 20.14 35 17 31.86 17 28 C 17 24.14 20.14 21 24 21 z M 50 55 C 48.34 55 47 56.34 47 58 L 47 104 C 47 105.66 48.34 107 50 107 C 51.66 107 53 105.66 53 104 L 53 58 C 53 56.34 51.66 55 50 55 z M 78 55 C 76.34 55 75 56.34 75 58 L 75 104 C 75 105.66 76.34 107 78 107 C 79.66 107 81 105.66 81 104 L 81 58 C 81 56.34 79.66 55 78 55 z"
                  /></svg
                >
              </button>
            </td>
          </tr>
        {/each}
      </tbody>
    </table>
  </div>
</div>
