<script>
  import MultiSelect from "svelte-multiselect";
  import apiInstance from "helpers/api.js";
  export let selectedMessages = [];
  export let fromId;
  let pollLoading = false;
  let polls = [];

  function isPollsAndSelectedPollsEqual(polls, selectedMessages) {
    if (polls.length !== selectedMessages.length) return false;
    const pollKeys = polls.map((poll) => poll.value);
    const selectedPollKeys = selectedMessages.map((poll) => poll.value);

    for (let i = 0; i < pollKeys.length; i++) {
      if (selectedPollKeys.indexOf(pollKeys[i]) === -1) return false;
    }
    return true;
  }

  $: pollsAndSelectedPollsEqual = isPollsAndSelectedPollsEqual(
    polls,
    selectedMessages
  );
  let pollsWithSelectAll = [];
  $: {
    if (!pollsAndSelectedPollsEqual) {
      pollsWithSelectAll = [
        {
          label: "Select All",
          value: "all",
          data: {},
        },
        ...polls,
      ];
    } else {
      pollsWithSelectAll = polls;
    }
  }

  $: {
    // remove select all from selectedMessages
    if (
      selectedMessages.length &&
      selectedMessages.find((item) => item.value === "all")
    ) {
      console.log("selectedMessages has poll");
      selectedMessages = polls
        .filter((item) =>
          item.label.toLowerCase().includes(searchPollText.toLowerCase())
        )
        .filter((item) => item.value !== "all");
    }
  }

  async function getAllPollMessages(params) {
    if (pollLoading) return;
    pollLoading = true;
    try {
      const { data } = await apiInstance.listWhatsAppMessages(params);
      polls = data.map((item) => ({
        label: item.body,
        value: item.messageId,
        data: item,
      }));
    } catch (error) {
      console.error(error);
    }
    pollLoading = false;
  }

  let searchPollText = "";
  let timeoutPoll;
  let getAllPollMessagesLoading;
  $: if (searchPollText) {
    clearTimeout(timeoutPoll);
    getAllPollMessagesLoading = true;
    timeoutPoll = setTimeout(async () => {
      getAllPollMessages({
        fromId,
        filter: {
          type: "poll_creation",
          body: searchPollText,
        },
      });
      getAllPollMessagesLoading = false;
    }, 1000);
  }
  $: {
    if (fromId) {
      polls = [];
      getAllPollMessages({
        fromId,
        filter: {
          type: "poll_creation",
        },
      });
    }
  }
  function onSelectAll() {
    selectedMessages = polls;
  }
</script>

<MultiSelect
  bind:options={pollsWithSelectAll}
  bind:value={selectedMessages}
  bind:searchText={searchPollText}
  outerDivClass="!bg-white"
  inputClass="!h-10"
  resetFilterOnAdd={false}
  key={(opt) => {
    return opt.value;
  }}
  filterFunc={(opt, text) => {
    if (!searchPollText) return true;

    if (opt.value === "all" && !pollsAndSelectedPollsEqual) return true;
    return opt.label.toLowerCase().includes(text.toLowerCase());
  }}
  duplicates={false}
  closeDropdownOnSelect={false}
  bind:loading={pollLoading}
>
  <svelte:fragment slot="option" let:option let:idx>
    {#if option.value === "all"}
      <div class="flex items-center" on:mouseup|stopPropagation={onSelectAll}>
        <div class="flex-1">
          <div class="text-sm font-semibold">Select All</div>
        </div>
      </div>
    {:else}
      <div class="flex items-center">
        <div class="flex-1">
          <div class="text-sm font-semibold">{option.label}</div>
        </div>
      </div>
    {/if}
  </svelte:fragment>
</MultiSelect>
