<script>
  import Select from "svelte-select";
  import SveltyPicker from "svelty-picker";
  import { v4 as uuidv4 } from "uuid";

  import apiInstance from "helpers/api.js";
  import { uploadFile } from "utils/firebaseStorageUtils.js";
  import GroupSelect from "components/Select/GroupSelect.svelte";
  import ProfileSelect from "components/Select/ProfileSelect.svelte";
  import GroupOfGroupSelect from "components/Select/GroupOfGroupSelect.svelte";
  import { get } from "svelte/store";
  import { userStore } from "store/svelteStore.js";
  import { failure, success } from "utils/toastUtils.js";
  import ContactSelect from "components/Select/ContactSelect.svelte";
  import MessageSentModal from "components/Modals/MessageSentModal.svelte";
  import RegularModal from "./RegularModal.svelte";

  let loading = false;
  let messageTypes = [
    "TEXT",
    "POLL",
    "IMAGE",
    "VIDEO",
    "DOCUMENT",
    "VOICE_NOTE",
  ];
  let messageType = { label: "TEXT", value: "TEXT" };
  let selectedWhatsAppProfile;
  let message = "";
  let files = [];
  let inputTypeFile;
  let selectedWhatsAppContacts = []; // { label: "test", value: "test" }
  let selectedWhatsAppGroupOfGroups;
  let selectedGroups = [];
  let pollOptions = [{ name: "", localId: 0 }];
  let allowMultipleAnswers = false;
  let pollName = "";

  $: {
    let ifAllPollOptionsAreFilled = pollOptions.find(
      (pollOption) => pollOption.name.trim() === ""
    );
    pollOptions =
      pollOptions.length === 11
        ? pollOptions
        : [
            ...pollOptions,
            ...(!ifAllPollOptionsAreFilled
              ? [{ name: "", localId: pollOptions.length }]
              : []),
          ];
  }

  $: {
    if (
      inputTypeFile &&
      (messageType.value === "IMAGE" ||
        messageType.value === "VIDEO" ||
        messageType.value === "DOCUMENT" ||
        messageType.value === "VOICE_NOTE")
    ) {
      inputTypeFile.value = "";
    }
  }

  $: noOfPollOptionFilled = pollOptions.filter(
    (item) => item.name.trim().length
  ).length;

  $: isAtleastTwoPollOptionsFilled = noOfPollOptionFilled >= 2;

  $: allSelectedContactsMap = [
    ...selectedGroups.map((item) => ({
      value: item.value,
      label: item.label,
    })),
    ...selectedWhatsAppContacts.map((item) => ({
      value: item.value,
      label: item.label,
    })),
    ...(selectedWhatsAppGroupOfGroups?.data?.groups || []).map((item) => ({
      value: item.serializedId,
      label: item.name,
    })),
  ];

  $: allSelectedContacts = Array.from(
    new Set(allSelectedContactsMap.map((item) => item.value))
  );

  $: isDisabledText =
    !selectedWhatsAppProfile || !allSelectedContacts?.length || !message.length;

  $: isDisabledPoll =
    !selectedWhatsAppProfile ||
    !allSelectedContacts?.length ||
    !pollName.length ||
    !isAtleastTwoPollOptionsFilled;

  $: isDisabledImageOrVideoOrDocumentOrVoiceNote =
    !selectedWhatsAppProfile || !allSelectedContacts?.length || !files?.length;

  $: isDisabled =
    messageType.value === "TEXT"
      ? isDisabledText
      : messageType.value === "POLL"
        ? isDisabledPoll
        : messageType.value === "IMAGE" ||
            messageType.value === "VIDEO" ||
            messageType.value === "DOCUMENT" ||
            messageType.value === "VOICE_NOTE"
          ? isDisabledImageOrVideoOrDocumentOrVoiceNote
          : true;

  let sentData = null;
  let dateTimeValue = null;

  async function assertVoiceFile(file) {
    return new Promise((resolve, reject) => {
      const audioElement = document.createElement("audio");
      audioElement.addEventListener("loadeddata", resolve);
      audioElement.addEventListener("error", (e) => {
        console.error(e);
        console.log("getAudioDuration: error loading audio");
        reject(new Error("Error loading voice note file"));
      });
      audioElement.src = URL.createObjectURL(file);
    });
  }

  async function sendMessage() {
    if (loading) return;
    loading = true;
    console.log("sending message");
    try {
      if (messageType.value === "VOICE_NOTE") {
        await assertVoiceFile(files[0]);
      }
      const filteredPollOptions = pollOptions.filter(
        (item) => item.name.trim().length
      );

      let fileUrl = "";

      const user = get(userStore);

      if (
        messageType.value === "IMAGE" ||
        messageType.value === "VIDEO" ||
        messageType.value === "DOCUMENT" ||
        messageType.value === "VOICE_NOTE"
      ) {
        fileUrl = await uploadFile(files[0], user.uid);
      }

      let delayMs = new Date(dateTimeValue).getTime() - new Date().getTime();

      const payload = {
        frontendMessageId: uuidv4(),
        contacts: allSelectedContacts,
        messageType: messageType.value,
        profileId: selectedWhatsAppProfile.value,
        delay: delayMs > 0 ? delayMs : 0,
        from: selectedWhatsAppProfile.data.clientInfo.wid._serialized,
        // delay: 1000,
        ...(messageType.value === "TEXT" && { message }),
        ...((messageType.value === "IMAGE" ||
          messageType.value === "VIDEO" ||
          messageType.value === "DOCUMENT" ||
          messageType.value === "VOICE_NOTE") && {
          fileOptions: {
            fileUrl,
            caption: message,
          },
        }),
        ...(messageType.value === "POLL" && {
          pollOptionsExtra: {
            pollName,
            type: "poll_creation",
            pollOptions: filteredPollOptions,
            pollSelectableOptionsCount: allowMultipleAnswers ? 0 : 1,
          },
        }),
      };

      const { data: ackPayload } =
        await apiInstance.sendWhatsAppMessage(payload);
      console.log("success", ackPayload);
      sentData = ackPayload.data;

      success(ackPayload.message);
    } catch (error) {
      console.error(error);
      failure(error.message);
    }
    loading = false;
  }
  export let toggleModal = () => {};
  export let entity = null;
  let modalTitle = "Send message";
</script>

<RegularModal {modalTitle} {toggleModal}>
  <div class="min-w-[70vw] max-w-[70vw] mb-4">
    <div class="flex-auto px-4 lg:px-10 py-10 pt-0 text-left">
      <form>
        <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
          Message Information
        </h6>
        <div class="flex flex-wrap">
          <div class="w-full lg:w-8/12 px-4">
            Select a Whatsapp Profile
            <div class="relative w-full mb-3">
              <ProfileSelect bind:selectedWhatsAppProfile />
            </div>
          </div>
          {#if selectedWhatsAppProfile}
            <div class="w-full lg:w-8/12 px-4">
              Select a group of groups
              <div class="relative w-full mb-3">
                <GroupOfGroupSelect
                  bind:selectedWhatsAppGroupOfGroups
                  fromId={selectedWhatsAppProfile?.data?.clientInfo?.wid
                    ?._serialized}
                />
              </div>
            </div>

            <div class="w-full lg:w-8/12 px-4">
              Select a time to send the message
              <div class="relative w-full mb-3">
                <SveltyPicker
                  inputClasses="px-3 py-3 placeholder-blueGray-300 text-blueGray-600 relative bg-white rounded text-sm outline-none focus:outline-none w-full"
                  format="yyyy-mm-dd hh:ii"
                  bind:value={dateTimeValue}
                  placeholder="Select date and time"
                  startDate={new Date()}
                  autoclose
                  clearBtn
                  manualInput
                ></SveltyPicker>
              </div>
            </div>

            <div class="w-full lg:w-8/12 px-4">
              Select Groups
              <div class="relative w-full mb-3">
                <GroupSelect
                  bind:selectedGroups
                  fromId={selectedWhatsAppProfile?.data?.clientInfo?.wid
                    ?._serialized}
                />
              </div>
            </div>

            <div class="w-full lg:w-8/12 px-4">
              Select Contacts
              <div class="relative w-full mb-3">
                <ContactSelect
                  fromId={selectedWhatsAppProfile?.data?.clientInfo?.wid
                    ?._serialized}
                  bind:selectedWhatsAppContacts
                />
              </div>
            </div>
            <div class="w-full lg:w-8/12 px-4">
              Select message type
              <div class="relative w-full mb-3">
                <Select
                  items={messageTypes}
                  bind:value={messageType}
                  clearable={false}
                />
              </div>
            </div>

            {#if messageType.value === "TEXT"}
              <div class="w-full lg:w-8/12 px-4">
                Message
                <div class="relative w-full mb-3">
                  <textarea
                    rows="4"
                    cols="80"
                    bind:value={message}
                    class="px-3 py-3 placeholder-blueGray-300 text-blueGray-600 relative bg-white rounded text-sm border-0 shadow outline-none focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    placeholder="Type a message..."
                  />
                </div>
              </div>
            {:else if messageType.value === "IMAGE" || messageType.value === "VIDEO" || messageType.value === "DOCUMENT" || messageType.value === "VOICE_NOTE"}
              <div class="w-full lg:w-8/12 px-4">
                {messageType.value === "IMAGE"
                  ? "Image"
                  : messageType.value === "VIDEO"
                    ? "Video"
                    : messageType.value === "DOCUMENT"
                      ? "Document"
                      : messageType.value === "VOICE_NOTE"
                        ? "Voice note"
                        : ""}
                <div class="relative w-full mb-3">
                  <input
                    type="file"
                    accept={messageType.value === "IMAGE"
                      ? "image/png, image/jpeg , image/jpg"
                      : messageType.value === "VIDEO"
                        ? "video/mp4"
                        : messageType.value === "DOCUMENT"
                          ? ".pdf,.doc,.docx,.xls,.xlsx,.ppt,.pptx,.txt"
                          : "" || messageType.value === "VOICE_NOTE"
                            ? "*"
                            : ""}
                    bind:files
                    bind:this={inputTypeFile}
                    class="px-3 py-3 placeholder-blueGray-300 text-blueGray-600 relative bg-white rounded text-sm border-0 shadow outline-none focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  />
                </div>
                <div class="relative w-full mb-3">
                  Caption
                  <div class="relative w-full mb-3">
                    <textarea
                      rows="4"
                      cols="80"
                      bind:value={message}
                      class="px-3 py-3 placeholder-blueGray-300 text-blueGray-600 relative bg-white rounded text-sm border-0 shadow outline-none focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      placeholder="Type a message..."
                    />
                  </div>
                </div>
              </div>
            {:else if messageType.value === "POLL"}
              <div class="w-full lg:w-8/12 px-4">
                Poll
                <div class="relative w-full mb-3">
                  <input
                    type="text"
                    bind:value={pollName}
                    class="px-3 py-3 placeholder-blueGray-300 text-blueGray-600 relative bg-white rounded text-sm border-0 shadow outline-none focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    placeholder="Poll name"
                  />
                </div>
                <div class="relative w-full mb-3">
                  <label
                    class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    for="allowMultipleAnswers"
                  >
                    Allow multiple answers
                  </label>
                  <input
                    type="checkbox"
                    id="allowMultipleAnswers"
                    bind:checked={allowMultipleAnswers}
                    class="form-checkbox h-5 w-5 text-blue-600"
                  />
                </div>

                {#each pollOptions as pollOption}
                  <div class="relative w-full mb-3">
                    <label
                      class="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      for={`poll-option-${pollOption.localId}`}
                    >
                      Poll option {pollOption.localId + 1}
                    </label>
                    <input
                      type="text"
                      id={`poll-option-${pollOption.localId}`}
                      bind:value={pollOption.name}
                      class="px-3 py-3 placeholder-blueGray-300 text-blueGray-600 relative bg-white rounded text-sm border-0 shadow outline-none focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      placeholder="Poll option"
                    />
                  </div>
                {/each}
              </div>
            {/if}
          {/if}
        </div>

        <button
          class="bg-black text-white active:bg-black font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150 text-right {loading ||
          loading ||
          isDisabled
            ? 'opacity-50 cursor-not-allowed'
            : ''}"
          type="button"
          disabled={loading || isDisabled}
          on:click={sendMessage}
        >
          Send message
        </button>

        <hr class="mt-6 border-b-1 border-blueGray-300" />
        {#if sentData}
          <MessageSentModal
            toggleModal={() => (sentData = null)}
            data={sentData}
            contacts={allSelectedContactsMap}
          />
        {/if}
      </form>
    </div>
  </div>
</RegularModal>
