<script>
  import MultiSelect from "svelte-multiselect";
  let types = [
    {
      label: "Chat",
      value: "chat",
    },
    {
      label: "Image",
      value: "image",
    },
    {
      label: "Audio",
      value: "audio",
    },
    {
      label: "Video",
      value: "video",
    },
    {
      label: "Document",
      value: "document",
    },
    {
      label: "Location",
      value: "location",
    },
    {
      label: "Sticker",
      value: "sticker",
    },
    {
      label: "Contact",
      value: "vcard",
    },
    {
      label: "Poll",
      value: "poll_creation",
    },
    {
      label: "Call log",
      value: "call_log",
    },
  ];
  export let selectedTypes;
  let searchText = "";
</script>

<MultiSelect
  bind:options={types}
  value={selectedTypes}
  on:change={(e) => {
    switch (e.detail.type) {
      case "add":
        selectedTypes = [...selectedTypes, e.detail.option];
        break;

      case "remove":
        selectedTypes = selectedTypes.filter(
          (item) => item.value !== e.detail.option.value
        );
        break;

      case "removeAll":
        selectedTypes = [];
        break;
      default:
        break;
    }
  }}
  resetFilterOnAdd={false}
  bind:searchText
  outerDivClass="!bg-white"
  inputClass="!h-10"
  duplicates={false}
  closeDropdownOnSelect={false}
/>
