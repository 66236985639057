<script>
  // Admin Layout
  import Auth from "./layouts/Auth.svelte";
  import Admin from "./layouts/Admin.svelte";
  import { SvelteToast } from "@zerodevx/svelte-toast";

  import WhatsAppProfile from "./layouts/WhatsAppProfile.svelte";
  import SendWhatsGroupMessage from "./layouts/SendWhatsGroupMessage.svelte";

  import { Router, Route } from "svelte-navigator";
  import { userStore } from "store/svelteStore.js";
  import { onMount } from "svelte";
  import { onAuthStateChanged } from "firebase/auth";
  import { auth } from "utils/firebase.js";
  import PrivateRoute from "components/Auth/PrivateRoute.svelte";
  import GroupLink from "./layouts/GroupLink.svelte";
  import GroupDetailsScreen from "./layouts/GroupDetailsScreen.svelte";
  import GroupOfGroups from "./layouts/GroupOfGroups.svelte";
  import GroupAnalytics from "./layouts/GroupAnalytics.svelte";
  import ScheduleMessage from "layouts/ScheduleMessage.svelte";

  let notSet = true;
  const options = {
    theme: {},
  };

  onMount(() => {
    onAuthStateChanged(auth, (user) => {
      console.log("user", user);
      userStore.set(user);
      notSet = false;
    });
  });
</script>

<Router>
  <Route path="/auth/login">
    <Auth />
  </Route>
  <Route path="/pre-group-verify">
    <GroupDetailsScreen />
  </Route>
  {#if notSet}
    <div>Logging in</div>
  {:else if !notSet}
    <PrivateRoute path="/">
      <SendWhatsGroupMessage />
    </PrivateRoute>
    <PrivateRoute path="/whatsapp-profiles">
      <WhatsAppProfile />
    </PrivateRoute>
    <PrivateRoute path="/schedule-messages">
      <ScheduleMessage />
    </PrivateRoute>
    <PrivateRoute path="/analytics">
      <GroupAnalytics />
    </PrivateRoute>
    <PrivateRoute path="/group-link">
      <GroupLink />
    </PrivateRoute>
    <PrivateRoute path="/url-generator">
      <Admin />
    </PrivateRoute>
    <PrivateRoute path="/group-of-groups">
      <GroupOfGroups />
    </PrivateRoute>
  {/if}
</Router>
<SvelteToast {options} />

<style>
  :root {
    --toastContainerTop: auto;
    --toastContainerBottom: 4rem;
    --toastContainerRight: auto;
    --toastContainerLeft: calc(100vw - 20rem);
  }
</style>
