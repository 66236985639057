import { baseURL } from "helpers/axiosInstance.js";
import { isLocal } from "./envUtils.js";

export function getGroupLink(id, returnType) {
  if (isLocal()) {
    if (returnType === "redirect") {
      return `${baseURL}/api/whatsapp/group-link/${id}?returnType=redirect`;
    }
    return `${baseURL}/api/whatsapp/group-link/${id}`;
  }
  if (returnType === "redirect") {
    return `${baseURL}/api/whatsapp/group-link/${id}?returnType=redirect`;
  }
  return `${baseURL}/api/whatsapp/group-link/${id}`;
}

export function getPreGroupLink(id) {
  return `${window.location.origin}/pre-group-verify/?id=${id}`;
}
