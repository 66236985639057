<script>
  import { onMount, tick } from "svelte";
  import Select from "svelte-select";
  import RegularModal from "./RegularModal.svelte";
  import { MultiSelect } from "svelte-multiselect";
  import apiInstance from "helpers/api.js";
  import { failure, success } from "utils/toastUtils.js";

  export let toggleModal = () => {};
  export let entity = null;
  let modalTitle = entity ? "Update Group Of Groups" : "Create Group Of Groups";

  let groupName = entity ? entity.name : "";

  let selectedWhatsAppProfile;
  let loading = false;

  let whatsappProfiles = [];
  let getListWhatsAppProfiles = false;

  let selectedWhatsAppContacts = []; // { label: "test", value: "test" }

  async function getWhatsAppProfiles() {
    if (getListWhatsAppProfiles) return;
    getListWhatsAppProfiles = true;
    try {
      const { data } = await apiInstance.listWhatsAppProfiles({
        filter: {
          state: "INITIALIZED",
        },
      });
      whatsappProfiles = data.map((item) => ({
        label: `${item.clientInfo.me.user} - ${item.clientInfo.pushName}`,
        value: item.id,
        data: item,
      }));
      console.log(whatsappProfiles);
      return whatsappProfiles;
    } catch (error) {
      console.error(error);
    }
    getListWhatsAppProfiles = false;
  }

  function formatWhatsAppContacts(data) {
    return data.map((item) => ({
      label: `${
        item.name || item.pushname || item.verifiedName || item.shortName
      }`,
      value: item._id,
    }));
  }

  let whatsAppContacts = [];
  let getWhatsAppContactsLoading = false;
  async function getWhatsAppContacts(filter = {}) {
    if (getWhatsAppContactsLoading) return;
    getWhatsAppContactsLoading = true;
    try {
      const { data } = await apiInstance.listWhatsAppContacts(filter);
      whatsAppContacts = formatWhatsAppContacts(data);
      return whatsAppContacts;
    } catch (error) {
      console.error(error);
    }
    getWhatsAppContactsLoading = false;
  }

  async function loadWhatsAppContacts(filterText) {
    const { data } = await apiInstance.listWhatsAppContacts({
      filter: {
        name: filterText,
      },
      fromId: selectedWhatsAppProfile.data.clientInfo.wid._serialized,
    });
    whatsAppContacts = formatWhatsAppContacts(data);
    return whatsAppContacts;
  }

  let searchText = "";
  let timeout;
  $: if (searchText) {
    loading = true;
    clearTimeout(timeout);
    timeout = setTimeout(async () => {
      loadWhatsAppContacts(searchText);
      // perform some fetch/database request here to get list of options matching searchText
      // options = await fetch(`https://example.com?search=${searchText}`)
      loading = false;
    }, 1000);
  }

  let once = false;

  $: {
    if (selectedWhatsAppProfile && !once) {
      whatsAppContacts = [];
      getWhatsAppContacts({
        fromId: selectedWhatsAppProfile.data.clientInfo.wid._serialized,
      }).then(() => {
        once = true;
        if (entity) {
          tick().then(() => {
            let _selectedWhatsAppContacts = formatWhatsAppContacts(
              entity.groups
            );
            selectedWhatsAppContacts = _selectedWhatsAppContacts;
          });
        }
      });
    }
  }

  let isCreateGroupOfGroupsLoading = false;
  console.log("groups", entity);

  function createGroupOfGroups() {
    isCreateGroupOfGroupsLoading = true;
    const groups = selectedWhatsAppContacts.map((item) => item.value);
    apiInstance
      .updateWhatsAppGroupOfGroups({
        groups,
        name: groupName,
        _id: entity?._id,
        fromId: selectedWhatsAppProfile.data.clientInfo.wid._serialized,
      })
      .then((res) => {
        console.log(res);
        isCreateGroupOfGroupsLoading = false;
        success("create a group of group successfully");
        toggleModal();
      })
      .catch((err) => {
        console.error(err);
        failure("Error in creating a group of group");
        isCreateGroupOfGroupsLoading = false;
      });
  }

  let selectedWhatsAppContactsDisabled = true;
  onMount(() => {
    getWhatsAppProfiles().then((whatsappProfiles) => {
      if (entity) {
        console.log("whatsappProfiles", whatsappProfiles);
        selectedWhatsAppProfile = whatsappProfiles.find(
          (item) => item.data.clientInfo.wid._serialized === entity.fromId
        );
      } else {
        selectedWhatsAppContactsDisabled = false;
      }
    });
  });

  $: isCreateGroupOfGroupsDisabled =
    !selectedWhatsAppProfile ||
    !selectedWhatsAppContacts.length ||
    !groupName.trim();
</script>

<RegularModal {modalTitle} {toggleModal}>
  <div class="min-w-[50vw] mb-4">
    <!-- input for group name -->
    <div class="mt-4">
      <div class="w-full lg:w-8/12 px-4">
        Group Of Groups Name
        <div class="relative w-full mb-3">
          <input
            type="text"
            class="border-1 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
            bind:value={groupName}
          />
        </div>
      </div>
    </div>

    <div class="mt-4">
      <div class="w-full lg:w-8/12 px-4">
        Select a Whatsapp Profile
        <div class="relative w-full mb-3">
          <Select
            disabled={selectedWhatsAppContactsDisabled}
            items={whatsappProfiles}
            bind:value={selectedWhatsAppProfile}
          >
            <div class="empty" slot="empty">No options</div>
          </Select>
        </div>
      </div>
    </div>
    {#if selectedWhatsAppProfile}
      <div class="mt-4">
        <div class="w-full lg:w-8/12 px-4">
          Select Contacts
          <div class="relative w-full mb-3">
            <MultiSelect
              bind:options={whatsAppContacts}
              bind:value={selectedWhatsAppContacts}
              bind:searchText
              duplicates={true}
              resetFilterOnAdd={false}
              outerDivClass="!bg-white"
              inputClass="!h-10"
              closeDropdownOnSelect={false}
              {loading}
            />
          </div>
        </div>
      </div>
      <div class="mt-4 text-center">
        <button
          class="bg-black text-white active:bg-black font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150 {loading ||
          isCreateGroupOfGroupsDisabled ||
          isCreateGroupOfGroupsLoading
            ? 'opacity-50 cursor-not-allowed'
            : ''}"
          type="button"
          disabled={isCreateGroupOfGroupsDisabled ||
            isCreateGroupOfGroupsLoading}
          on:click={createGroupOfGroups}
        >
          {entity ? "Update Group Of Groups" : "Create Group Of Groups"}
        </button>
      </div>
    {/if}
  </div>
</RegularModal>
