<script>
  import SendMessageModal from "components/Modals/SendMessageModal.svelte";
  import ScheduledMessageTable from "components/Tables/ScheduledMessageTable/ScheduledMessageTable.svelte";
  import DashboardMessagesTable from "components/Tables/DashboardMessagesTable/DashboardMessagesTable.svelte";
  import Switch from "svelte-switch";

  let showModal = false;
  export let color = "light";

  function toggleModal() {
    showModal = !showModal;
  }

  let isScheduled = false;
  function onSwitch() {
    isScheduled = !isScheduled;
  }
</script>

<div
  class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded {color ===
  'light'
    ? 'bg-white'
    : 'bg-black text-white'}"
>
  <div class="rounded-t mb-0 px-4 py-3 border-0">
    <div class="flex flex-wrap items-center">
      <div
        class="relative w-full px-4 max-w-full flex-grow flex-1 flex justify-between"
      >
        <h3 class="font-semibold text-lg text-blueGray-700 flex">
          {#if isScheduled}
            Schedule message list
          {:else}
            Send message list
          {/if}
          <div class="ml-4">
            <Switch on:change={onSwitch} checked={isScheduled} />
          </div>
        </h3>
        <button
          class="bg-black text-white active:bg-black font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
          type="button"
          on:click={toggleModal}>Send message</button
        >
        {#if showModal}
          <SendMessageModal {toggleModal} />
        {/if}
      </div>
    </div>
  </div>
  <div class="block w-full overflow-x-auto">
    {#if isScheduled}
      <ScheduledMessageTable />
    {:else}
      <DashboardMessagesTable />
    {/if}
  </div>
</div>
