<script>
  import { onMount } from "svelte";
  import { RRule } from "rrule";

  let selectedDate = new Date();
  let events = [];

  let rruleOptions = {
    freq: RRule.WEEKLY,
    dtstart: selectedDate,
  };

  let rrule;

  onMount(() => {
    rrule = new RRule(rruleOptions);
  });

  function addEvent() {
    events = [...events, selectedDate];
  }

  function removeEvent(index) {
    events = events.filter((_, i) => i !== index);
  }
  $: rruleDays = rrule ? generateDays() : [];

  function generateDays() {
    const days = [];
    const currentDate = new Date(selectedDate);
    const endDate = new Date(currentDate);
    endDate.setDate(endDate.getDate() + 6); // Assuming a week view

    const occurrences = rrule.between(currentDate, endDate, true);

    for (let i = 0; i < occurrences.length; i += 7) {
      days.push({
        date: occurrences.slice(i, i + 7),
        isInMonth: true, // You might need to adjust this based on your actual calendar logic
      });
    }

    return days;
  }
</script>

<div>
  <h2>Calendar</h2>
  <div>
    <label for="date">Select Date:</label>
    <input type="date" bind:value={selectedDate} />
    <button on:click={addEvent}>Add Event</button>
  </div>
  <div>
    <h3>Selected Events:</h3>
    <ul>
      {#each events as event, index (event)}
        <li>
          {event.toLocaleDateString()}
          <button on:click={() => removeEvent(index)}>Remove</button>
        </li>
      {/each}
    </ul>
  </div>
</div>

<div>
  <h3>Calendar View:</h3>
  <div>
    <table>
      <thead>
        <tr>
          <th>Sun</th>
          <th>Mon</th>
          <th>Tue</th>
          <th>Wed</th>
          <th>Thu</th>
          <th>Fri</th>
          <th>Sat</th>
        </tr>
      </thead>
      <tbody>
        {#each $rruleDays as { date, isInMonth }, weekIndex (rrule)}
          <tr>
            {#each date as day (weekIndex)}
              <td
                class:outside={!isInMonth}
                class:selected={events.some(
                  (event) => event.toDateString() === day.toDateString()
                )}
              >
                {day.getDate()}
              </td>
            {/each}
          </tr>
        {/each}
      </tbody>
    </table>
  </div>
</div>
