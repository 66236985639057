<script>
  import PollBarChat from "components/Cards/PollBarChat.svelte";
  import MessageSelect from "components/Select/MessageSelect.svelte";
  import "flatpickr/dist/flatpickr.css";

  import apiInstance from "helpers/api.js";

  export let props;
  $: selectedWhatsAppProfile = props.selectedWhatsAppProfile;

  let fromId;
  $: fromId = selectedWhatsAppProfile?.data?.clientInfo?.wid?._serialized;

  let selectedMessages = [];

  let analyticsLoading = false;
  let analyticsData = [];
  async function getWhatsAppVotes(params = {}) {
    if (analyticsLoading) return;
    analyticsLoading = true;
    try {
      const { data } = await apiInstance.listWhatsAppVotes(params);
      analyticsData = data;
      console.log({ data });
      analyticsLoading = false;
      return analyticsData;
    } catch (error) {
      console.error(error);
      analyticsLoading = false;
    }
  }

  $: {
    if (selectedMessages.length !== 0) {
      getWhatsAppVotes({
        filter: {
          // messageIds: [
          //   "3EB0F0217BDB638ACD7CA0",
          //   "3EB0F05791826326E96A15",
          //   "3EB040545F8FDFC38BADA1",
          //   "3EB0775DB3D2E15C0CB14E",
          //   "3EB0BF765559E71BB03485",
          // ],
          messageIds: selectedMessages.map((message) => message.data.messageId),
        },
      });
    }
  }
</script>

<div class="flex flex-wrap pt-5">
  <div class="w-full flex px-4">
    <div class="px-4">
      Select multiple messages for which you want to see the poll data
    </div>
    <div class="relative lg:w-4/12 mb-3 mr-5">
      <MessageSelect {fromId} bind:selectedMessages />
    </div>
  </div>
</div>
<div class="flex flex-wrap pt-5">
  {#if analyticsData.length !== 0}
    <PollBarChat data={analyticsData} />
  {/if}
</div>
