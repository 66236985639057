<script>
  import MultiSelect from "svelte-multiselect";
  import apiInstance from "helpers/api.js";
  export let selectedGroups = [];
  export let fromId;
  let groupLoading = false;
  let groups = [];

  async function getAllGroup(params) {
    if (groupLoading) return;
    groupLoading = true;
    try {
      const { data } = await apiInstance.listWhatsAppGroups({
        ...params,
        kind: "group",
      });
      groups = data.map((item) => ({
        label: item.formattedTitle,
        value: item.serializedId,
        data: item,
      }));
    } catch (error) {
      console.error(error);
    }
    groupLoading = false;
  }
  let searchGroupText = "";
  let timeoutGroup;
  let getAllGroupLoading;
  $: if (searchGroupText) {
    clearTimeout(timeoutGroup);
    getAllGroupLoading = true;
    timeoutGroup = setTimeout(async () => {
      getAllGroup({
        fromId,
        filter: {
          name: searchGroupText,
        },
      });
      getAllGroupLoading = false;
    }, 1000);
  }
  $: {
    if (fromId) {
      groups = [];
      getAllGroup({
        fromId,
      });
    }
  }
</script>

<MultiSelect
  bind:options={groups}
  bind:value={selectedGroups}
  bind:searchText={searchGroupText}
  outerDivClass="!bg-white"
  inputClass="!h-10"
  resetFilterOnAdd={false}
  duplicates={false}
  closeDropdownOnSelect={false}
  bind:loading={groupLoading}
/>
