<script>
  import Select from "svelte-select";
  import apiInstance from "helpers/api.js";
  import { getGroupLink, getPreGroupLink } from "utils/urlUtils.js";
  import { get } from "svelte/store";
  import { userStore } from "store/svelteStore.js";
  import { uploadFile } from "utils/firebaseStorageUtils.js";
  import { failure, success } from "utils/toastUtils.js";
  import ContactSelect from "components/Select/ContactSelect.svelte";
  import ProfileSelect from "components/Select/ProfileSelect.svelte";

  let loading = false;
  let selectedWhatsAppProfile;
  let admins = [];
  let groupName = "";
  let groupDescription = "";
  let files;
  let groupData = {
    serializedGroupData: [
      {
        inviteCode: "K2TgoDh0GRRECjJeg1xVrD",
        serializedGid: "120363322189274304@g.us",
      },
      {
        inviteCode: "FX4tinpul152CkjfmgRnHo",
        serializedGid: "120363324015835091@g.us",
      },
    ],
    currentGroupIndex: 0,
    clientGroupName: "Fingyan Academy A",
    profileId: "QlrPzSUUSZKioF0wTgoL",
    admins: [],
    id: "u2vrgcHfW4VyJ4gyX34C9t",
  };
  let groupTypes = [
    {
      label: "Community",
      value: 1,
    },
    {
      label: "Group",
      value: 0,
    },
  ];
  let selectedGroupType = groupTypes[0];

  async function createUrl() {
    if (loading) return;
    loading = true;
    const user = get(userStore);
    const payload = {
      groupName,
      admins: admins.map((item) => item.value),
      profileId: selectedWhatsAppProfile.value,
      isCommunity: selectedGroupType.value === 1,
      description: groupDescription,
      ...(files?.length
        ? { imageUrl: await uploadFile(files[0], user.uid) }
        : {}),
      // admins: [],
      owner: selectedWhatsAppProfile.wid,
    };
    console.log(payload);

    try {
      const { data } = await apiInstance.createWhatsAppGroupLink(payload);
      console.log(data);
      groupData = data;
      success("Group link created successfully");
    } catch (error) {
      console.error(error);
      failure(error.message || "Something went wrong");
    }
    loading = false;
  }

  $: console.log(selectedWhatsAppProfile);

  $: isDisabled =
    !selectedWhatsAppProfile || loading || groupName.trim().length === 0;
</script>

<div class="flex flex-wrap">
  <div class="w-full px-4">
    <div
      class="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0"
    >
      <div class="rounded-t bg-white mb-0 px-6 py-6">
        <div class="text-center flex justify-between">
          <h6 class="text-blueGray-700 text-xl font-bold">My account</h6>
          <button
            class="bg-black text-white active:bg-black font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150 {isDisabled
              ? 'opacity-50 cursor-not-allowed'
              : ''}"
            type="button"
            disabled={isDisabled}
            on:click={createUrl}
          >
            Create url
          </button>
        </div>
      </div>
      <div class="flex-auto px-4 lg:px-10 py-10 pt-0">
        <form>
          <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
            Url Information
          </h6>
          <div class="flex flex-wrap">
            <div class="w-full lg:w-8/12 px-4">
              Select a Whatsapp Profile
              <div class="relative w-full mb-3">
                <ProfileSelect bind:selectedWhatsAppProfile />
              </div>
            </div>
            {#if selectedWhatsAppProfile}
              <div class="w-full lg:w-8/12 px-4">
                Select group type
                <div class="relative w-full mb-3">
                  <Select items={groupTypes} bind:value={selectedGroupType}>
                    <div class="empty" slot="empty">No options</div>
                  </Select>
                </div>
              </div>
              <div class="w-full lg:w-8/12 px-4">
                Group Name
                <div class="relative w-full mb-3">
                  <input
                    type="text"
                    bind:value={groupName}
                    class="px-3 py-3 placeholder-blueGray-300 text-blueGray-600 relative bg-white rounded text-sm border-0 shadow outline-none focus:outline-none focus:ring w-full"
                    placeholder="Group Name"
                    style="transition: all 0.15s ease 0s;"
                  />
                </div>
              </div>

              <div class="w-full lg:w-8/12 px-4">
                Select admins
                <div class="relative w-full mb-3">
                  <ContactSelect
                    fromId={selectedWhatsAppProfile?.data?.clientInfo?.wid
                      ?._serialized}
                    bind:selectedWhatsAppContacts={admins}
                  />
                </div>
              </div>

              <div class="w-full lg:w-8/12 px-4">
                Group Description
                <div class="relative w-full mb-3">
                  <input
                    type="text"
                    bind:value={groupDescription}
                    class="px-3 py-3 placeholder-blueGray-300 text-blueGray-600 relative bg-white rounded text-sm border-0 shadow outline-none focus:outline-none focus:ring w-full"
                    placeholder="Group Description"
                    style="transition: all 0.15s ease 0s;"
                  />
                </div>
              </div>
              <div class="w-full lg:w-8/12 px-4">
                Group Image
                <div class="relative w-full mb-3">
                  <input
                    type="file"
                    bind:files
                    class="px-3 py-3 placeholder-blueGray-300 text-blueGray-600 relative bg-white rounded text-sm border-0 shadow outline-none focus:outline-none focus:ring w-full"
                    placeholder="Group Description"
                    style="transition: all 0.15s ease 0s;"
                  />
                </div>
              </div>
            {/if}
          </div>
        </form>
        <hr class="mt-6 border-b-1 border-blueGray-300" />

        {#if groupData?.id}
          <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
            Url Information with lead capture
          </h6>
          {getPreGroupLink(groupData.id)}
          <h6 class="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
            Url Information without lead capture
          </h6>
          {getGroupLink(groupData.id, "redirect")}
        {/if}
      </div>
    </div>
  </div>
</div>
