<script>
  // components for this layout
  import AdminNavbar from "components/Navbars/AdminNavbar.svelte";
  import FooterAdmin from "components/Footers/FooterAdmin.svelte";
  import HeaderStats from "components/Headers/HeaderStats.svelte";
  import Sidebar from "components/Sidebar/Sidebar.svelte";
  import ScheduleMessage from "views/ScheduleMessage.svelte";
</script>

<Sidebar />
<div class="relative md:ml-64 bg-blueGray-100">
  <AdminNavbar header="Send group message" />
  <HeaderStats />
  <div class="px-4 md:px-10 mx-auto w-full -m-24">
    <ScheduleMessage />
    <FooterAdmin />
  </div>
</div>
